import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { IconButton } from "@material-ui/core";
import PublicIcon from "@material-ui/icons/Public";
import AddIcon from "@material-ui/icons/Add";
import { colors } from "@material-ui/core";

import { SearchInput } from "components";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    // height: '20px',
    display: "flex",
    alignItems: "center",
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  mapIcon: {
    marginLeft: "auto",
    color: theme.palette.text.secondary,
    backgroundColor: colors.grey[200],
    "&:hover": {
      backgroundColor: colors.grey[300],
    },
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
  },
}));

const AssetToolbar = (props) => {
  const {
    className,
    searchAssetHandler,
    switchMap,
    addHandler,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>
        <Button
          color="primary"
          variant="contained"
        >
          Add Asset
        </Button> */}
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          onChange={searchAssetHandler}
          placeholder="Search Landforms"
        />
        <IconButton
          className={clsx(classes.mapIcon, className)}
          color="inherit"
          onClick={addHandler}
        >
          <AddIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.mapIcon, className)}
          color="inherit"
          onClick={switchMap}
        >
          <PublicIcon />
        </IconButton>
      </div>
    </div>
  );
};

AssetToolbar.propTypes = {
  className: PropTypes.string,
  searchAssetHandler: PropTypes.func,
  switchMap: PropTypes.func,
};

export default AssetToolbar;
