export const mapReportIcons = (type) => {
  let icon;
  switch (type) {
    case "closure":
      icon = "report_closure_icon";
      break;
    case "fire":
      icon = "report_fire_icon";
      break;
    case "flood":
      icon = "report_flood_icon";
      break;
    case "hazard":
      icon = "report_closure_icon";
      break;
    case "lightning":
      icon = "report_lightning_icon";
      break;
    case "rain":
      icon = "report_rain_icon";
      break;
    case "snow":
      icon = "report_snow_icon";
      break;
    case "wildlife":
      icon = "report_wildlife_icon";
      break;
    case "wind":
      icon = "report_wind_icon";
      break;
    default:
      icon = "report_closure_icon";
      break;
  }
  return icon;
};
