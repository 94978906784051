import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    minHeight: "0",
    height: "6.5vh",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    // marginLeft: theme.spacing(1),
    // color: colors.blueGrey[800],
    display: 'flex',
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
  },
}));

const Logout = (props) => {
  const { clicked } = props;

  const classes = useStyles();

  return (
    <Button className={classes.signOutButton} color="inherit" onClick={clicked}>
      <div className={classes.icon}>
        <InputIcon />
      </div>
      LOGOUT
    </Button>
  );
};

Logout.propTypes = {
  className: PropTypes.string,
  logOutHandler: PropTypes.func,
};

export default Logout;
