import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import Amplify from "aws-amplify";

import * as serviceWorker from "./serviceWorker";
import authReducer from "./store/reducers/auth";
import assetReducer from "./store/reducers/assets";
import activityReducer from "./store/reducers/activity"
import trailReducer from "./store/reducers/trails";
import pointReducer from "./store/reducers/points";
import reportReducer from "./store/reducers/reports";
import alertReducer from "./store/reducers/alerts";
import userReducer from "./store/reducers/users";
import orgReducer from "./store/reducers/organizations";
import mapReducer from "./store/reducers/map";
import App from "./App";
import config from "./config";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: config.apiGateway.NAME,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  auth: authReducer,
  assets: assetReducer,
  users: userReducer,
  orgs: orgReducer,
  userActivities: activityReducer,
  trails: trailReducer,
  points: pointReducer,
  reports: reportReducer,
  alerts: alertReducer,
  map: mapReducer,
});

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

serviceWorker.unregister();
