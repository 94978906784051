import React, { useState, useEffect, useRef } from "react";
import { StaticMap } from "react-map-gl";
import { connect } from "react-redux";
import DeckGL from "deck.gl";
import { makeStyles } from "@material-ui/core/styles";
import { WebMercatorViewport } from "@math.gl/web-mercator";

import { renderTrailLayer } from "./Layers/renderTrailLayer";
import { renderPointLayer } from "./Layers/renderPointLayer";
import { renderUserActivityLayer } from "./Layers/renderUserActivityLayer";
import { renderReportLayer } from "./Layers/renderReportLayer";
import { renderAlertLayer } from "./Layers/renderAlertLayer";
import * as actions from "../../store/actions/index";
import { containsObject } from "../../utilities/utilities";
import { mapReportIcons } from "../../utilities/reportIcons";
import { pointLocationTypes } from "../../utilities/mapConstants";
import HoverCard from "./HoverCard/HoverCard";
import SelectionPanel from "./SelectionCard/SelectionPanel";
import LocationDetail from "./SelectionCard/LocationDetail";
import AddLocation from "./AddLocation/AddLocation";
import { Container, FormControl, FormGroup, FormControlLabel, Checkbox, Button, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions,Typography, Popover, ButtonGroup, Accordion, AccordionSummary, AccordionDetails, Snackbar, } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const geo = require("../../test/boulder_hiking_trails.json");

// TODO: move tokens and style route to enviroment files

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoib3V0d2F5LWFkbWluIiwiYSI6ImNrMmdmNXVkbTA5bXYzbXBnY2VhYTg3c3AifQ.Bskr-yB4IOvaRwYpOLt2zg";

const MAPBOX_STYLE = "mapbox://styles/outway-admin/ckrdpsvy72pqo18pe6gaht732";

// const INITIAL_VIEW_STATE = {
//   latitude: 39.9486,
//   longitude: -105.5639,
//   zoom: 12,
//   bearing: 0,
//   pitch: 0
// };

const viewport = new WebMercatorViewport({
  //TODO: calculate accurate zoom levels from bounding box
  zoom: 12,
  bearing: 0,
  pitch: 0,
});

const useStyles = makeStyles((theme) => ({
  selectedPanel: {
    position: "absolute",
    overflow: "scroll",
    zIndex: 110,
    backgroundColor: "#FFFFFF",
    "border-left": "1px solid #757575",
    height: "100%",
    top: 0,
    right: 0,
    width: "300px",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingRight: "16px",
    paddingLeft: "16px",
  },
  layersToggle: {
    position: "absolute",
    zIndex: 110,
    bottom: 35,
    left: 15,
    paddingLeft: "5px",
    backgroundColor: "rgba(255,255,255,.7)",
    borderRadius: 5
  },
  checkbox: {
    color: "#231F20",
  },
  addLocationButton: {
    position: "absolute",
    right: 0,
    margin: theme.spacing(3),
    backgroundColor: "rgba(255,255,255,1)",
    "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.background.default }
  },
  selectLocationSnackBar: {
    backgroundColor: "white",
    color: "black",
    fontSize: theme.typography.body1.size

  },
  dialogForm: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1),
      minWidth: "60px",
    }
  },
  addingPopover: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  addingPopoverIcons: {
    marginRight: theme.spacing(1),
    fontSize: '1rem'
  },
  mainDropdown: {
    position: 'absolute',
    top: 24,
    left: 24,
    width: '30%',
  }
}));

const findColor = (surface) => {
  let color = [];
  switch (surface) {
    case "ground":
      color = [112, 92, 35];
      break;
    case "concrete":
      color = [105, 104, 104];
      break;
    case "gravel":
      color = [145, 123, 71];
      break;
    case "paved":
      color = [0, 0, 0];
      break;
    case "dirt":
      color = [150, 110, 0];
      break;
    case "fine_gravel":
      color = [145, 123, 71];
      break;
    case "unpaved":
      color = [189, 157, 32];
      break;
    case "rocks":
      color = [189, 114, 23];
      break;
    case "wood":
      color = [107, 59, 0];
      break;
    default:
      color = [107, 101, 101];
      break;
  }
  return color;
};

const calcBounds = (data) => {
  let [minLng, minLat, maxLng, maxLat] = [
    data[0].path[0][0],
    data[0].path[0][1],
    data[0].path[0][0],
    data[0].path[0][1],
  ];
  let i;
  let j;
  for (i = 0; i < data.length; i++) {
    for (j = 0; j < data[i].path.length; j++) {
      if (data[i].path[j][0] < minLng) {
        minLng = data[i].path[j][0];
      } else if (data[i].path[j][0] > maxLng) {
        maxLng = data[i].path[j][0];
      }
      if (data[i].path[j][1] < minLat) {
        minLat = data[i].path[j][1];
      } else if (data[i].path[j][1] > maxLat) {
        maxLat = data[i].path[j][1];
      }
    }
  }
  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
};


//#region CLEAN LOCATION DATA
const getRuns = (data) => {
  let runs = [];
  data.features.forEach((feature) => {
    let path = feature.geometry.coordinates;
    if (path.length === 1) {
      path = path[0];
    }
    runs.push({
      name: feature.properties.name,
      id: feature.id,
      path: path,
      color: findColor(feature.properties.surface),
      properties: feature.properties,
    });
  });
  return runs;
};

const getTrails = (data) => {
  let trails = [];
  if (data?.response) {
    data.response.forEach((trail) => {
      let path = trail.geom.coordinates;
      let surfaceType = null;
      if (trail.trail_attribute) {
        surfaceType = trail.trail_attribute.surface_type;
      }
      const color = findColor(surfaceType);
      trails.push({
        name: trail.name,
        id: trail.id,
        type: trail.type,
        path: path,
        color: color,
        attributes: trail.trail_attribute,
        usages: trail.usage,
      });
    });
  }
  return trails;
};

const getPoints = (data) => {
  let points = [];
  if (data?.response) {
    for (const point of data.response) {
      if (point.geom.coordinates && point.geom.coordinates.length === 2) {
        points.push({
          name: point.name,
          id: point.id,
          coordinates: point.geom.coordinates,
          path: point.geom.coordinates,
          color: null, // TODO: get color based on status
          type: 'point_location',
          point_type: point.type,
          attributes: point[point.type + '_attribute'],
          icon:point.type+"_icon",
        });
      }
    }
  }
  return points;
}

const getUserActivities = (data) => {
  let userActivities = [];
  if (data?.response) {
    for (const activity of data.response) {
      let path = [];
      for (const dataPoint of activity.activity_data) {
        const point = [dataPoint.longitude, dataPoint.latitude];
        path.push(point);
      }
      userActivities.push({
        name: activity.name,
        id: activity.id,
        path,
        color: [102, 240, 255],
        type: 'user_activity'
      });
    }
  }
  return userActivities;
}

const getAlerts = (data) => {
  let alerts = [];
  if (data?.response) {
    for (const alert of data.response) {
      const icon = mapReportIcons(alert?.alert_attribute?.type);
      alerts.push({
        name: alert.name,
        id: alert.id,
        coordinates: alert.geom.coordinates,
        path: alert.geom.coordinates,
        color: null, // TODO: get color based on status/type
        type: alert.type,
        icon,
        attributes: alert[alert.type + '_attribute']
      });
    }
  }
  return alerts;
}

const getReports = (data) => {
  let reports = [];
  if (data?.response) {
    for (const report of data.response) {
      const icon = mapReportIcons(report?.report_attribute?.type);
      reports.push({
        name: report.name,
        id: report.id,
        coordinates: report.geom.coordinates,
        path: report.geom.coordinates,
        color: null, // TODO: get color based on status
        type: report.type,
        report_type: report.report_attribute.type,
        icon,
        note: report.report_attribute.note,
        status: report.report_attribute.status,
      });
    }
  }
  return reports;
}
//#endregion CLEAN LOCATION DATA

// TODO: get rid of use of 'geo' from json, just use data from db
const { longitude, latitude, zoom } = viewport.fitBounds(
  calcBounds(getRuns(geo))
);

const INITIAL_VIEW_STATE = {
  latitude: latitude,
  longitude: longitude,
  zoom: Math.pow(6, zoom),
  pitch: 0,
  bearing: 0,
};
//TODO: calculate accurate zoom

const Map = (props) => {
  const classes = useStyles();

  const deck = useRef(null);
  const map = useRef(null);
  useEffect(() => {
    props.getTrails();
    props.getPoints();
    props.getUserActivities();
    props.getAlerts();
    props.getReports();
  }, []); // why does this infinite loop without array parameter?

  const [hover, setHover] = useState({
    x: 0,
    y: 0,
    hoveredObject: null,
  });

  //#region MAP LAYERS TOGGLE
  const [layersToggle, setLayersToggle] = useState({
    showTrailLayer: true,
    showPointLayer: true,
    showUserActivityLayer: false,
    showAlertLayer: true,
    showReportLayer: false,
  });

  const handleLayerToggle = (event) => {
    setLayersToggle({ ...layersToggle, [event.target.name]: event.target.checked });
  };
  //#endregion MAP LAYERS TOGGLE

  //#region POINT LOCATION FILTERS
  const [pointFilterToggle, setPointFilterToggle] = useState(false);

  const handlePointFilterOpen = () => {
    setPointFilterToggle(true);
  };

  const handlePointFilterClose = () => {
    setPointFilterToggle(false);
  };

  const [pointLocationFilter, setPointLocationFilter] = useState(pointLocationTypes.reduce((acc,curr) => (acc[curr]=true,acc),{}));

  const handlePointFilterToggle = (event) => {
    setPointLocationFilter({ ...pointLocationFilter, [event.target.name]: event.target.checked });
  };

  const applyPointLocationFilter = () => {
    // console.log(pointLocationFilter);
    const locationTypes = [];
    for (const key in pointLocationFilter) {
      if (pointLocationFilter[key]) locationTypes.push(key);
    }
    props.getPoints(locationTypes);
    handlePointFilterClose();
  };
  //#endregion POINT LOCATION FILTERS

  const [isEditing, switchEditing] = useState(true);

  const _onHover = ({ x, y, object }) => {
    const label = object ? object.name : null;
    setHover({ x, y, hoveredObject: object, label });
  };

  //#region DETAIL CARD
  const [openDetailCard, setOpenDetailCard] = useState(false);
  const [detailCardObject, setDetailCardObject] = useState({});

  const toggleOpenDetailCard = () => {
    setOpenDetailCard(!openDetailCard);
  };

  const handleOpenDetailCard = (object) => {
    setDetailCardObject(object);
  }
  //#endregion DETAIL CARD

  //#region ADDING POPOVER
  const [addingPopoverAnchorEl, setAddingPopoverAnchorEl] = useState(null);

  const handleOpenAddingPopover = (event) => {
    setAddingPopoverAnchorEl(event.currentTarget)
  };
  const handleCloseAddingPopover = () => {
    setAddingPopoverAnchorEl(null);
  };
  const openAddingPopover = Boolean(addingPopoverAnchorEl);
  //#endregion ADDING POPOVER

  //#region ADD LOCATION
  const [isAdding, setAdding] = useState(false);
  const [addingLocationType, setAddingLocationType] = useState(null);
  const [addLocationCoordinates, setAddLocationCoordinates] = useState([]);

  const addLocation = (type) => {
    setAddingLocationType(type);
    setAdding(true);
    setAddingPopoverAnchorEl(null);
  };

  const cancelAddLocation = () => {
    setAddingLocationType(null);
    setAdding(false);
    setAddingPopoverAnchorEl(null);
  }

  const [addingDialog, setAddingDialog] = useState(false);

  const handleAddingDialogClose = () => {
    setAddingDialog(false);
  };
  //#endregion ADD LOCATION

  const _onClick = (click) => {
    const { layer, object, coordinate } = { ...click };
    if (isAdding) {
      setAddLocationCoordinates(coordinate);
      setAddingDialog(true);
      setAdding(false);
    }
    else if (layer) {
      if (props.selection) {
        props.updateSelection(
          containsObject(object, props.selection)
            ? [object]
            : props.selection.concat(object)
        );
      } else {
        props.updateSelection([object]);
      }
    } else {
      props.updateSelection(null);
    }
    // if (layer) {
    //   // The viewport is a WebMercatorViewport instance
    //   const {viewport} = layer.context;
    //   const {longitude, latitude, zoom} = viewport.fitBounds([
    //     [object.minLng, object.minLat],
    //     [object.maxLng, object.maxLat]
    //   ]);
    //   // Zoom to the object
    //   this.setState({
    //     viewState: {longitude, latitude, zoom}
    //   })
  };

  // const _createSpotObject = ({ click }, object) => {
  //   const { latlng } = [...click];
  //   return {};
  // };

  function getMapCursor(){
    if(isAdding){
      return "cell";
    } else if (hover.hoveredObject) {
      return "pointer";
    } else {
      return "default";
    }
  }

  return (
    <div>
      {hover.hoveredObject && (
        <HoverCard
          horizontal={hover.x}
          vertical={hover.y}
          object={hover.hoveredObject}
        />
      )}
      {props.selection && !openDetailCard && (
        <Container className={classes.selectedPanel}>
          {props.selection.map((selected) => {
            return (
              <SelectionPanel
                key={"SelectCard" + selected.id}
                locationObject={selected}
                toggleDetailCard={toggleOpenDetailCard}
                setDetailCardData={handleOpenDetailCard}
              />
            );
          })}
        </Container>
      )}
      <DeckGL
        controller
        getCursor={getMapCursor}
        initialViewState={INITIAL_VIEW_STATE}
        layers={[
          layersToggle.showTrailLayer ? [ renderTrailLayer({
            data: getTrails(props.trailsArray),
            selected: props.selection,
            onHover: (hover) => _onHover(hover),
          }) ] : null,
          layersToggle.showPointLayer ? [ renderPointLayer({
            data: getPoints(props.pointsArray),
            selected: props.selection,
            onHover: (hover) => _onHover(hover),
          }) ] : null,
          layersToggle.showUserActivityLayer ? [ renderUserActivityLayer({
            data: getUserActivities(props.userActivitiesArray),
            selected: props.selection,
            onHover: (hover) => _onHover(hover),
          }) ] : null,
          layersToggle.showAlertLayer ? [ renderAlertLayer({
            data: getAlerts(props.alertsArray),
            selected: props.selection,
            onHover: (hover) => _onHover(hover),
          }) ] : null,
          layersToggle.showReportLayer ? [ renderReportLayer({
            data: getReports(props.reportsArray),
            selected: props.selection,
            onHover: (hover) => _onHover(hover),
          }) ] : null,
        ]}
        onClick={(click) => _onClick(click)}
        pickingRadius={5}
        ref={deck}
      >
        <StaticMap
          mapboxApiAccessToken={MAPBOX_TOKEN}
          mapStyle={MAPBOX_STYLE}
          preventStyleDiffing={true}
          ref={map}
        />
      </DeckGL>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isAdding}
        message="Select Location"
        ContentProps={{
          className: classes.selectLocationSnackBar
        }}
        action={
          <React.Fragment>
            <Button size="small" onClick={cancelAddLocation}>
              Cancel
            </Button>
            <IconButton size="xs" aria-label="close" color="error" onClick={cancelAddLocation}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      {/* LOCATION DETAIL CARD */}
      {openDetailCard && Object.keys(detailCardObject).length ?
        <LocationDetail
          onClose={toggleOpenDetailCard}
          data={detailCardObject}
        /> : null
      }

      {/* ADD LOCATION CARD */}
      {/* TODO: clean data before it's passed here so the AddLocation component can be fully dynamic */}
      {addingDialog && addLocationCoordinates.length ?
        <AddLocation
          onClose={handleAddingDialogClose}
          type={addingLocationType}
          locationCoordinates={addLocationCoordinates}
        /> : null
      }

      {/* ADDING BUTTON & POPOVER */}
      <div>
        <IconButton 
          onClick={handleOpenAddingPopover} 
          disabled={addingDialog} 
          className={classes.addLocationButton} 
          aria-label="add"><AddIcon/></IconButton>
        <Popover
          className={classes.addingPopover}
          open={openAddingPopover}
          anchorEl={addingPopoverAnchorEl}
          onClose={handleCloseAddingPopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 130,
          }}
        >
          <ButtonGroup orientation="vertical" variant="text" className={classes.addingPopover}>
            <Button onClick={addLocation.bind(this,'point')}><AddIcon className={classes.addingPopoverIcons}/>Point</Button>
            <Button disabled={true}><AddIcon className={classes.addingPopoverIcons}/>Trail</Button>
            <Button onClick={addLocation.bind(this,'alert')}><AddIcon className={classes.addingPopoverIcons}/>Alert</Button>
          </ButtonGroup>
        </Popover>
      </div>

      {/* LOCATION FILTERS */}
      {/* just make the checkboxes local states? any need to add to redux? */}
      {isAdding || (addingDialog && addLocationCoordinates.length) ? 
        null 
      : 
        <Accordion className={classes.mainDropdown}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}><Typography variant='subtitle2'>See trails, reports, and more</Typography></AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={layersToggle.showTrailLayer} onChange={handleLayerToggle} name="showTrailLayer" checkedIcon={<CheckBoxIcon className={classes.checkbox}/>}/>}
                  label="Trails"/>
                <Box display="flex">
                  <FormControlLabel
                    control={<Checkbox checked={layersToggle.showPointLayer} onChange={handleLayerToggle} name="showPointLayer" checkedIcon={<CheckBoxIcon className={classes.checkbox}/>}/>}
                    label="Points"/>
                    <IconButton size="small" onClick={handlePointFilterOpen} aria-label="filters"><ArrowRightIcon/></IconButton>
                </Box>
                <FormControlLabel
                  control={<Checkbox checked={layersToggle.showUserActivityLayer} onChange={handleLayerToggle} name="showUserActivityLayer" checkedIcon={<CheckBoxIcon className={classes.checkbox}/>}/>}
                  label="User Data"/>
                <FormControlLabel
                  control={<Checkbox checked={layersToggle.showReportLayer} onChange={handleLayerToggle} name="showReportLayer" checkedIcon={<CheckBoxIcon className={classes.checkbox}/>}/>}
                  label="Reports"/>
                <FormControlLabel
                  control={<Checkbox checked={layersToggle.showAlertLayer} onChange={handleLayerToggle} name="showAlertLayer" checkedIcon={<CheckBoxIcon className={classes.checkbox}/>}/>}
                  label="Alerts"/>
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      }

      {/* ADDITIONAL POINT LOCATION FILTERS */}
      <Dialog open={pointFilterToggle} onClose={handlePointFilterClose} aria-labelledby="point-filter-dialog-title">
        <DialogTitle id="point-filter-dialog-title">Point Location Filters</DialogTitle>
        <DialogContent>
          <FormControl>
            <FormGroup>
              {pointLocationTypes.length ? pointLocationTypes.map((type, index) =>
                <FormControlLabel
                  control={<Checkbox checked={pointLocationFilter[type]} onChange={handlePointFilterToggle} name={type} checkedIcon={<CheckBoxIcon className={classes.checkbox}/>}/>}
                  label={type}/>
              ) : null}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePointFilterClose} color="primary">
            Cancel
          </Button>
          <Button onClick={applyPointLocationFilter} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showMaps: state.map.showMaps,
    MapBoxAPIAccessToken: state.map.MapBoxAPIAccessToken,
    mapStyle: state.map.mapStyle,
    viewport: state.map.viewport,
    selection: state.assets.selection,
    trailsArray: state.trails.trails,
    pointsArray: state.points.points,
    userActivitiesArray: state.userActivities.userActivities,
    reportsArray: state.reports.reports,
    alertsArray: state.alerts.alerts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchShowMap: () => dispatch(actions.switchShowMap()),
    updateSelection: (selected) => dispatch(actions.selectedAsset(selected)),
    getTrails: () => dispatch(actions.getTrails()),
    getPoints: (types) => dispatch(actions.getPoints(types)),
    getUserActivities: () => dispatch(actions.getActivities()),
    getReports: () => dispatch(actions.getReports()),
    getAlerts: () => dispatch(actions.getAlerts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
