import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import { AccountProfile, AccountDetails } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Account = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        alignContent="center"
        direction="column"
        spacing={4}
      >
        <Grid item xs={12} sm={10} md={8} lg={8}>
          <AccountProfile />
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={8}>
          <AccountDetails />
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
