import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Modal,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import * as actions from "../../../../store/actions/index";
import { SearchInput } from "components";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    minWidth: 120,
    margin: theme.spacing(1),
  },
}));

const UsersToolbar = (props) => {
  const { className, signup, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    console.log("handling change");
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleAddUser = (event) => {
    console.log(event);
    console.log(formState);
    // TODO: refresh table without reloading
    // event.preventDefault();
    signup(formState.values);
    setOpen(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button> */}
        <Button className={classes.exportButton}>Export</Button>
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add user
        </Button>
      </div>
      <Modal className={classes.modal} open={open} onClose={handleClose}>
        <form className={classes.modalContent} onSubmit={handleAddUser}>
          <Grid container spacing={3} className="pt2">
            <Grid item xs={6}>
              <TextField
                placeholder="First Name"
                variant="outlined"
                name="first_name"
                onChange={handleChange}
                type="text"
                value={formState.values.first_name || ""}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Last Name"
                variant="outlined"
                name="last_name"
                onChange={handleChange}
                type="text"
                value={formState.values.last_name || ""}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Email"
                variant="outlined"
                name="email"
                onChange={handleChange}
                type="text"
                value={formState.values.email || ""}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="add-user-org-select-label">Org</InputLabel>
                <Select
                  autoWidth
                  labelId="add-user-org-select-label"
                  name="org"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.org || ""}
                >
                  <MenuItem value={1}>test</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Button color="primary" variant="contained" type="submit">
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search user"
        />
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    // authenticated: state.auth.authenticated,
    // loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signup: (data) => dispatch(actions.signup(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersToolbar);
