import distance from "@turf/distance";
import { point } from "@turf/helpers";

export const containsObject = (obj, list) => {
  for (let i = 0; i < list.length; i++) {
    // console.log(isEquivalent(list[i],obj))
    // if (isEquivalent(list[i],obj)) {
    //   return true;
    // }
    if (list[i].id === obj.id) {
      return true;
    }
  }
  return false;
};

export const findDistance = (path) => {
  let length = 0;

  const segmentLength = (currentCoordinate, previousCoordinate) =>
    distance(point(currentCoordinate), point(previousCoordinate), {
      units: "miles",
    });

  for (let i = 1; i < path.length; i++) {
    length += segmentLength(path[i], path[i - 1]);
  }

  return length;
};
// const isEquivalent = (a, b) => {
//   // Create arrays of property names
//   var aProps = Object.getOwnPropertyNames(a);
//   var bProps = Object.getOwnPropertyNames(b);

//   // If number of properties is different,
//   // objects are not equivalent
//   if (aProps.length != bProps.length) {
//     return false;
//   }

//   for (var i = 0; i < aProps.length; i++) {
//     var propName = aProps[i];

//     // If values of same property are not equal,
//     // objects are not equivalent
//     if (a[propName] !== b[propName]) {
//         console.log(a[propName],b[propName])
//       return false;
//     }
//   }

//   // If we made it this far, objects
//   // are considered equivalent
//   return true;
// }
