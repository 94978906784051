import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../utility";

const initialState = {
  error: null,
  loading: false,
  assets: null,
  adding: false,
  selection: null,
};

const getAssetsStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getAssetsSuccess = (state, action) => {
  return updateObject(state, {
    assets: action.assets,
    error: null,
    loading: false,
  });
};

const getAssetsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const addAssetStart = (state) => {
  return updateObject(state, {
    adding: true,
    error: null,
  });
};

const addAssetSuccess = (state, action) => {
  return updateObject(state, {
    // assets: action.assets,
    adding: false,
    error: null,
  });
};

const addAssetCancel = (state, action) => {
  return updateObject(state, {
    error: null,
    adding: false,
  });
};

const selectAsset = (state, action) => {
  return updateObject(state, {
    selection: action.selection,
  });
};

const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ASSETS_START:
      return getAssetsStart(state, { error: null, loading: true });
    case actionTypes.GET_ASSETS_SUCCESS:
      return getAssetsSuccess(state, action);
    case actionTypes.GET_ASSETS_FAIL:
      return getAssetsFail(state, action);

    case actionTypes.ADD_ASSET_START:
      return addAssetStart(state, { error: null, loading: true });
    case actionTypes.ADD_ASSET_SUCCESS:
      return addAssetSuccess(state, action);
    case actionTypes.ADD_ASSET_CANCEL:
      return addAssetCancel(state, action);

    case actionTypes.SELECT_ASSET:
      return selectAsset(state, action);
    default:
      return state;
  }
};

export default assetReducer;
