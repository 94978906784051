import { IconLayer } from "deck.gl";
import { ICON__MAPPING } from "./iconMapping";

export const renderReportLayer = (props) => {
  const { data, onHover, selected } = props;

  const reportLayer = [new IconLayer({
        id: "user-reports",
        pickable: true,
        data,
        iconAtlas: 'https://s3.us-west-2.amazonaws.com/outway.io/iconAtlas.png',
        iconMapping: ICON__MAPPING,
        getIcon: d => d.icon,
        sizeScale: 15,
        getPosition: d => d.coordinates,
        getSize: d => 1.5,
        onHover,
  })]

  return reportLayer;
};

export default renderReportLayer;
