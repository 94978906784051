import { API } from "aws-amplify";

import * as actionTypes from "./actionTypes";
import config from "../../config";

export const getOrgStart = () => {
  return {
    type: actionTypes.GET_ORG_START,
  };
};

export const getOrgSuccess = (org) => {
  return {
    type: actionTypes.GET_ORG_SUCCESS,
    org: org,
  };
};

export const getOrgFail = (err) => {
  return {
    type: actionTypes.GET_ORG_FAIL,
    error: err,
  };
};

export const getOrg = (orgId) => {
  return (dispatch) => {
    dispatch(getOrgStart());
    const myInit = {
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
    };
    API.get("outway", "/organizations/" + orgId, myInit)
      .then((res) => {
        dispatch(getOrgSuccess(res.response[0]));
      })
      .catch((err) => console.log(err));
  };
};

export const getOrgsStart = () => {
  return {
    type: actionTypes.GET_ORGS_START,
  };
};

export const getOrgsSuccess = (orgs) => {
  return {
    type: actionTypes.GET_ORGS_SUCCESS,
    orgs: orgs,
  };
};

export const getOrgsFail = (err) => {
  return {
    type: actionTypes.GET_ORGS_FAIL,
    error: err,
  };
};

// currently does not exist
export const getOrgs = () => {
  return (dispatch) => {
    dispatch(getOrgsStart());
    const myInit = {
      // OPTIONAL
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
    };
    API.get("outway", "/organizations", myInit)
      .then((res) => {
        console.log(res);
        dispatch(getOrgsSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const createOrgStart = () => {
  return {
    type: actionTypes.CREATE_ORG_START,
  };
};

export const createOrgSuccess = (assets) => {
  return {
    type: actionTypes.CREATE_ORG_SUCCESS,
    assets: assets,
  };
};

export const createOrgFail = (err) => {
  return {
    type: actionTypes.CREATE_ORG_FAIL,
    error: err,
  };
};

export const createOrg = (data) => {
  return (dispatch) => {
    // console.log(config);
    dispatch(createOrgStart());
    const myInit = {
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
      body: data,
    };
    console.log(myInit);
    API.post("outway", "/orgs", myInit)
      .then((res) => {
        console.log(res);
        dispatch(createOrgSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const updateOrgStart = () => {
  return {
    type: actionTypes.UPDATE_ORG_START,
  };
};

export const updateOrgSuccess = (assets) => {
  return {
    type: actionTypes.UPDATE_ORG_SUCCESS,
    assets: assets,
  };
};

export const updateOrgFail = (err) => {
  return {
    type: actionTypes.UPDATE_ORG_FAIL,
    error: err,
  };
};

export const updateOrg = (object) => {
  return (dispatch) => {
    console.log(config);
    dispatch(updateOrgStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.put("outway", "/orgs/" + object.id, myInit)
      .then((res) => {
        console.log(res);
        dispatch(updateOrgSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const deleteOrgStart = () => {
  return {
    type: actionTypes.DELETE_ORG_START,
  };
};

export const deleteOrgSuccess = (assets) => {
  return {
    type: actionTypes.DELETE_ORG_SUCCESS,
    assets: assets,
  };
};

export const deleteOrgFail = (err) => {
  return {
    type: actionTypes.DELETE_ORG_FAIL,
    error: err,
  };
};

export const deleteOrg = (object) => {
  return (dispatch) => {
    console.log(config);
    dispatch(deleteOrgStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.del('outway', '/orgs/' + object.id, myInit)
      .then(res => {
        console.log(res);
        dispatch(deleteOrgSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const clearOrg = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_ORG,
    });
  };
};

export const updateTenantStart = () => {
  return {
    type: actionTypes.UPDATE_TENANT_START,
  };
};

export const updateTenantSuccess = (assets) => {
  return {
    type: actionTypes.UPDATE_TENANT_SUCCESS,
    assets: assets,
  };
};

export const updateTenantFail = (err) => {
  return {
    type: actionTypes.UPDATE_TENANT_FAIL,
    error: err,
  };
};

export const updateTenant = (data, orgId) => {
  return (dispatch) => {
    dispatch(updateTenantStart());
    const myInit = {
      // OPTIONAL
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
      body: data, // OPTIONAL
    };
    API.put("outway", "/tenants/" + data.id, myInit)
      .then((res) => {
        console.log(res);
        dispatch(updateTenantSuccess(res));
        dispatch(getOrg(orgId));
      })
      .catch((err) => console.log(err));
  };
};

export const addTenantStart = () => {
  return {
    type: actionTypes.ADD_TENANT_START,
  };
};

export const addTenantSuccess = (assets) => {
  return {
    type: actionTypes.ADD_TENANT_SUCCESS,
    assets: assets,
  };
};

export const addTenantFail = (err) => {
  return {
    type: actionTypes.ADD_TENANT_FAIL,
    error: err,
  };
};

export const addTenant = (data, orgId) => {
  return (dispatch) => {
    dispatch(addTenantStart());
    const myInit = {
      // OPTIONAL
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
      body: data,
    };
    API.post("outway", "/tenants", myInit)
      .then((res) => {
        console.log(res);
        dispatch(addTenantSuccess(res));
        dispatch(getOrg(orgId));
      })
      .catch((err) => console.log(err));
  };
};

export const deleteTenantStart = () => {
  return {
    type: actionTypes.DELETE_TENANT_START,
  };
};

export const deleteTenantSuccess = assets => {
  return {
    type: actionTypes.DELETE_TENANT_SUCCESS,
    assets: assets,
  };
};

export const deleteTenantFail = err => {
  return {
    type: actionTypes.DELETE_TENANT_FAIL,
    error: err,
  };
};

export const deleteTenant = (data, orgId) => {
  console.log(data);
  return async dispatch => {
    dispatch(deleteTenantStart());
    const myInit = {
      // OPTIONAL
      headers: { 'x-api-key': 'fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c' },
    };
    // something wrong with actual delete call on server side...
    API.del('outway', '/tenants/' + data.tenantId, myInit)
      .then(res => {
        console.log(res);
        dispatch(deleteTenantSuccess(res));
        // dispatch(getOrg(orgId));
      })
      .catch(err => console.log(err));
  };
};
