import React, { Component } from "react";
import DeckGL from "deck.gl";
import MapGL from "react-map-gl";
import { WebMercatorViewport } from "@math.gl/web-mercator";
import { connect } from "react-redux";

import { renderLayers } from "../../../../components/MapTools/Layers/renderLayers";
import HoverCard from "./HoverCard";
import * as actions from "../../../../store/actions/index";

// const useStyles = makeStyles(theme => ({
//   mapActionBar:{
//     // display: 'inline-block',
//     width:'100%',
//     position: 'absolute',
//     paddingTop: theme.spacing(2),
//     paddingLeft: theme.spacing(4),
//     paddingRight: theme.spacing(4),
//     marginRight: theme.spacing(4),
//     zIndex: 100
//   }
// }));

const viewport = new WebMercatorViewport({
  latitude: 39.9486,
  longitude: -105.5639,
  zoom: 12,
  maxZoom: 16,
  loading: true,
});
// .fitBounds([[-122.4, 37.7], [-122.5, 37.8]], {
//   padding: 20,
//   offset: [0, -100]
// });

class AssetMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: {
        x: 0,
        y: 0,
        hoveredObject: null,
      },
      selection: [],
      viewport: viewport,
      isAdding: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this._resize);
    this._resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._resize);
  }

  _calcBounds = (data) => {
    let [minLng, minLat, maxLng, maxLat] = [
      data[0].path[0][0],
      data[0].path[0][1],
      data[0].path[0][0],
      data[0].path[0][1],
    ];
    let i;
    let j;
    for (i = 0; i < data.length; i++) {
      for (j = 0; j < data[i].path.length; j++) {
        if (data[i].path[j][0] < minLng) {
          minLng = data[i].path[j][0];
        } else if (data[i].path[j][0] > maxLng) {
          maxLng = data[i].path[j][0];
        }
        if (data[i].path[j][1] < minLat) {
          minLat = data[i].path[j][1];
        } else if (data[i].path[j][1] > maxLat) {
          maxLat = data[i].path[j][1];
        }
      }
    }
    return {
      minLng: minLng,
      minLat: minLat,
      maxLng: maxLng,
      maxLat: maxLat,
    };
  };
  _resize = () => {
    this._onViewportChange({
      width: "inherit",
      height: window.innerHeight,
    });
  };

  _onViewportChange(viewport) {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport },
    });
  }

  _onHover({ x, y, object }) {
    const label = object ? object.name : null;
    this.setState({ hover: { x, y, hoveredObject: object, label } });
  }

  _onClick = ({ layer, object }) => {
    if (layer) {
      this.setState({ selection: this.state.selection.concat(object) });
    } else {
      this.setState({ selection: [] });
    }
    console.log(this.state.selection);
    // if (layer) {
    //   // The viewport is a WebMercatorViewport instance
    //   const {viewport} = layer.context;
    //   const {longitude, latitude, zoom} = viewport.fitBounds([
    //     [object.minLng, object.minLat],
    //     [object.maxLng, object.maxLat]
    //   ]);
    //   // Zoom to the object
    //   this.setState({
    //     viewState: {longitude, latitude, zoom}
    //   })
  };

  _onAdd() {
    this.setState({ isAdding: true });
  }

  render() {
    // const classes = useStyles();

    const data = this.props.data;

    if (!data.length) {
      return null;
    }
    const { hover } = this.state;
    console.log(`translate(${hover.x}px, ${hover.y}px)`);
    return (
      <div>
        {hover.hoveredObject && (
          <HoverCard
            horizontal={hover.x}
            object={hover.hoveredObject}
            vertical={hover.y}
            // style={{transform: `translate(${hover.x}px, ${hover.y}px)`}}
          />
        )}
        {this.props.children}
        <MapGL
          {...this.state.viewport}
          mapboxApiAccessToken={this.props.MapBoxAPIAccessToken}
          mapStyle={this.props.mapStyle}
          onViewportChange={(viewport) => this._onViewportChange(viewport)}
        >
          <DeckGL
            controller={{ dragPan: false }}
            getCursor={() => "default"}
            layers={renderLayers({
              data: this.props.data,
              selected: this.state.selection,
              onHover: (hover) => this._onHover(hover),
              onClick: (click) => this._onClick(click),
            })}
            onClick={(click) => this._onClick(click)}
            viewState={this.state.viewport}
          />
        </MapGL>
        {/* <DeckGL
          layers = {renderLayers(
            {data: this.props.data,
              selected:this.state.selection,
              onHover: hover => this._onHover(hover),
              onClick: click => this._onClick(click)
            })
          }
          controller={true}
          initialViewState = {{
            latitude: 39.9486,
            longitude: -105.5639,
            zoom: 12,
            maxZoom: 16,
            bearing:0,
            pitch:0 }}
        >
          <StaticMap 
            // mapboxApiAccessToken={this.props.MapBoxAPIAccessToken}
            // mapStyle={this.props.mapStyle} 
          />
        </DeckGL> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showMaps: state.map.showMaps,
    MapBoxAPIAccessToken: state.map.MapBoxAPIAccessToken,
    mapStyle: state.map.mapStyle,
    viewport: state.map.viewport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchShowMap: () => dispatch(actions.switchShowMap()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetMap);
