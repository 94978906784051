import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../utility";

const initialState = {
  error: null,
  loading: false,
  alerts: null,
  adding: false,
  selection: null,
};

const getAlertsStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getAlertsSuccess = (state, action) => {
  return updateObject(state, {
    alerts: action.alerts,
    error: null,
    loading: false,
  });
};

const getAlertsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const createAlertStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const createAlertSuccess = (state, action) => {
  return updateObject(state, {
    alerts: action.alerts, //TODO: check if this needs to be adjusted to just append to the alerts state.
    error: null,
    loading: false,
  });
};

const createAlertFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const updateAlertStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const updateAlertSuccess = (state, action) => {
  return updateObject(state, {
    // alerts: action.alerts, //TODO: check if this needs to be adjusted to just append to the alerts state.
    error: null,
    loading: false,
  });
};

const updateAlertFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const deleteAlertStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const deleteAlertSuccess = (state, action) => {
  return updateObject(state, {
    alerts: action.alerts, //TODO: check if this needs to be adjusted to just append to the alerts state.
    error: null,
    loading: false,
  });
};

const deleteAlertFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALERTS_START:
      return getAlertsStart(state, { error: null, loading: true });
    case actionTypes.GET_ALERTS_SUCCESS:
      return getAlertsSuccess(state, action);
    case actionTypes.GET_ALERTS_FAIL:
      return getAlertsFail(state, action);
    case actionTypes.CREATE_ALERT_START:
      return createAlertStart(state, { error: null, loading: true });
    case actionTypes.CREATE_ALERT_SUCCESS:
      return createAlertSuccess(state, action);
    case actionTypes.CREATE_ALERT_FAIL:
      return createAlertFail(state, action);
    case actionTypes.UPDATE_ALERT_START:
      return updateAlertStart(state, { error: null, loading: true });
    case actionTypes.UPDATE_ALERT_SUCCESS:
      return updateAlertSuccess(state, action);
    case actionTypes.UPDATE_ALERT_FAIL:
      return updateAlertFail(state, action);
    case actionTypes.DELETE_ALERT_START:
      return deleteAlertStart(state, { error: null, loading: true });
    case actionTypes.DELETE_ALERT_SUCCESS:
      return deleteAlertSuccess(state, action);
    case actionTypes.DELETE_ALERT_FAIL:
      return deleteAlertFail(state, action);

    // case actionTypes.SELECT_ALERT:
    //   return selectAlert(state, action);
    default:
      return state;
  }
};

export default alertReducer;
