import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from "@material-ui/styles";
import validate from "validate.js";
import PropTypes from "prop-types";

import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";
import validators from "./common/validators";
import Routes from "./Routes";
import FullScreenSpinner from "./components/FullScreenSpinner/FullScreenSpinner";
import * as actions from "./store/actions/index";

// Charlie: what is this?
validate.validators = {
  ...validate.validators,
  ...validators,
};

const App = ({
  validateSession,
  authenticated,
  authIsLoading,
  getUser,
  getUserIsLoading,
  cognitoUser,
  currentUser,
  getTrails,
}) => {
  useEffect(() => {
    if (!authenticated && !authIsLoading) {
      validateSession();
    } else if (authenticated && !currentUser && !getUserIsLoading) {
      getUser(cognitoUser);
    }
  }, [authenticated]);

  if ((!authenticated && authIsLoading) || (authenticated && !currentUser)) {
    return <FullScreenSpinner />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    );
  }
};

App.propTypes = {
  validateUser: PropTypes.func,
  history: PropTypes.object,
  errorMessage: PropTypes.string,
  authenticated: PropTypes.bool,
  authIsLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    authIsLoading: state.auth.isLoading,
    cognitoUser: state.auth.cognitoUser,
    currentUser: state.users.currentUser,
    getUserIsLoading: state.users.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (cognitoUser) => dispatch(actions.getUser(cognitoUser)),
    onTryAutoSignIn: () => dispatch(actions.authCheckState()),
    validateSession: () => dispatch(actions.validateUserSession()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
