import { API } from "aws-amplify";

import * as actionTypes from "./actionTypes";
import config from "../../config";

export const getActivitiesStart = () => {
  return {
    type: actionTypes.GET_ACTIVITIES_START,
  };
};

export const getActivitiesSuccess = (activities) => {
  return {
    type: actionTypes.GET_ACTIVITIES_SUCCESS,
    userActivities: activities,
  };
};

export const getActivitiesFail = (err) => {
  return {
    type: actionTypes.GET_ACTIVITIES_FAIL,
    error: err,
  };
};

export const getActivities = () => {
  return (dispatch) => {
    dispatch(getActivitiesStart());
    const myInit = {
      // OPTIONAL
      headers: {
        "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c",
      },
    };
    API.get("outway", "/user_activity", myInit)
      .then((res) => {
        console.log(res);
        dispatch(getActivitiesSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const createActivityStart = () => {
  return {
    type: actionTypes.CREATE_ACTIVITY_START,
  };
};

export const createActivitySuccess = (activity) => {
  return {
    type: actionTypes.CREATE_ACTIVITY_SUCCESS,
    userActivities: activity,
  };
};

export const createActivityFail = (err) => {
  return {
    type: actionTypes.CREATE_ACTIVITY_FAIL,
    error: err,
  };
};

export const createActivity = (object) => {
  return (dispatch) => {
    dispatch(createActivityStart());
    const myInit = {
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
      body: object,
    };
    API.post("outway", "/user_activity/", myInit)
      .then((res) => {
        console.log(res);
        dispatch(createActivitySuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const updateActivityStart = () => {
  return {
    type: actionTypes.UPDATE_ACTIVITY_START,
  };
};

export const updateActivitySuccess = (activity) => {
  return {
    type: actionTypes.UPDATE_ACTIVITY_SUCCESS,
    userActivities: activity,
  };
};

export const updateActivityFail = (err) => {
  return {
    type: actionTypes.UPDATE_ACTIVITY_FAIL,
    error: err,
  };
};

export const updateActivity = (object) => {
  return (dispatch) => {
    dispatch(updateActivityStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.put("outway", "/user_activity/" + object.id, myInit)
      .then((res) => {
        console.log(res);
        dispatch(updateActivitySuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const deleteActivityStart = () => {
  return {
    type: actionTypes.DELETE_ACTIVITY_START,
  };
};

export const deleteActivitySuccess = (activity) => {
  return {
    type: actionTypes.DELETE_ACTIVITY_SUCCESS,
    userActivities: activity,
  };
};

export const deleteActivityFail = (err) => {
  return {
    type: actionTypes.DELETE_ACTIVITY_FAIL,
    error: err,
  };
};

export const deleteActivity = (object) => {
  return (dispatch) => {
    dispatch(deleteActivityStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.del('outway', '/user_activity/' + object.id, myInit)
      .then(res => {
        console.log(res);
        dispatch(deleteActivitySuccess(res));
      })
      .catch((err) => console.log(err));
  };
};
