export const ICON__MAPPING = {
    alert_icon: { x: 0, y: 0, width: 235, height: 256 },
    bathroom_icon: { x: 235, y: 0, width: 256, height: 256 },
    information_icon: { x: 491, y: 0, width: 256, height: 256 },
    parking_lot_icon: { x: 747, y: 0, width: 256, height: 256 },
    point_location_shelter_icon: { x: 1003, y: 0, width: 256, height: 256 },
    point_of_interest_icon: { x: 1259, y: 0, width: 256, height: 256 },
    ranger_station_icon: { x: 1515, y: 0, width: 256, height: 256 },
    recycle_icon: { x: 1771, y: 0, width: 256, height: 256 },
    report_closure_icon: { x: 0, y: 256, width: 312, height: 280 },
    report_fire_icon: { x: 312, y: 256, width: 312, height: 280 },
    report_flood_icon: { x: 624, y: 256, width: 312, height: 280 },
    report_lightning_icon: { x: 936, y: 256, width: 312, height: 280 },
    report_rain_icon: { x: 1248, y: 256, width: 312, height: 280 },
    report_snake_icon: { x: 1560, y: 256, width: 312, height: 280 },
    report_snow_icon: { x: 0, y: 536, width: 312, height: 280 },
    report_trail_conditions_icon: { x: 312, y: 536, width: 312, height: 280 },
    report_wildlife_icon: { x: 624, y: 536, width: 315, height: 285 },
    report_wind_icon: { x: 939, y: 536, width: 312, height: 280 },
    trailhead_icon: { x: 1251, y: 536, width: 256, height: 254 },
    trash_icon: { x: 1507, y: 536, width: 256, height: 252 },
    drinking_water_icon: { x: 1763, y: 536, width: 256, height: 256 }
  };

