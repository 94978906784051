import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../utility";

const initialState = {
  showMaps: true,
  MapBoxAPIAccessToken:
    "pk.eyJ1Ijoib3V0d2F5LWFkbWluIiwiYSI6ImNrMmdmNXVkbTA5bXYzbXBnY2VhYTg3c3AifQ.Bskr-yB4IOvaRwYpOLt2zg",
  mapStyle: "mapbox://styles/outway-admin/ckrdpsvy72pqo18pe6gaht732",
  viewport: null,
  isDragable: true,
  isEditing: false,
  addSpotLocation: false,
};

const switchShowMap = (state, action) => {
  return updateObject(state, {
    showMap: !state.showMap,
    error: null,
    loading: false,
  });
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SWITCH_SHOWMAP:
      return switchShowMap(state, action);
    default:
      return state;
  }
};

export default mapReducer;
