import axios from "axios";

import * as actionTypes from "./actionTypes";

export const getAssetsStart = () => {
  return {
    type: actionTypes.GET_ASSETS_START,
  };
};

export const getAssetsSuccess = (assets) => {
  return {
    type: actionTypes.GET_ASSETS_SUCCESS,
    assets: assets,
  };
};

export const getAssetsFail = (err) => {
  return {
    type: actionTypes.GET_ASSETS_FAIL,
    error: err,
  };
};

export const getAssets = () => {
  return (dispatch) => {
    dispatch(getAssetsStart());
    const url =
      "https://v543mt8yu2.execute-api.us-west-2.amazonaws.com/Demo/location/runs";
    axios
      .get(url)
      .then((response) => {
        const responseData = JSON.parse(response.data.body);
        console.log(responseData);
        let data = [];
        const findColor = (difficulty) => {
          switch (difficulty) {
            case "expert":
              return [0, 0, 0];
            // break;
            case "difficult":
              return [0, 0, 0];
            // break;
            case "intermediate":
              return [17, 0, 255];
            // break;
            case "easy":
              return [51, 153, 51];
            // break;
            default:
              return [0, 0, 0];
            // break;
          }
        };
        const findImage = (difficulty) => {
          switch (difficulty) {
            case "expert":
              return "/images/icons/ski-double-black-diamond.png";
            // break;
            case "difficult":
              return "/images/icons/ski-black-diamond.svg";
            // break;
            case "intermediate":
              return "/images/icons/ski-blue.png";
            // break;
            case "easy":
              return "/images/icons/ski-green.png";
            // break;
            default:
              return "/images/icons/ski-black-diamond.png";
            // break;
          }
        };
        // console.log(responseData[0])
        responseData.map((d) =>
          data.push({
            id: d.id,
            path: JSON.parse(d.geom).coordinates,
            name: d.name,
            difficulty: d.difficulty,
            color: findColor(d.difficulty),
            iconUrl: findImage(d.difficulty),
          })
        );
        console.log(data);
        dispatch(getAssetsSuccess(data));
      })
      .catch((err) => {
        // console.log(err);
        dispatch(getAssetsFail(err));
      });
  };
};

export const addAssetStart = () => {
  return {
    type: actionTypes.ADD_ASSET_START,
  };
};

///TODO: Add addAsset async function here

export const addAssetSuccess = (assets) => {
  return {
    type: actionTypes.ADD_ASSET_SUCCESS,
    assets: assets,
  };
};

export const addAssetCancel = () => {
  return {
    type: actionTypes.ADD_ASSET_CANCEL,
  };
};

export const selectedAsset = (selected) => {
  return {
    type: actionTypes.SELECT_ASSET,
    selection: selected,
  };
};
