export const trailSurfaceTypes = {
  dirt: 'Dirt',
  concrete: 'Concrete',
  paved: 'Paved',
  gravel: 'Gravel',
  fine_gravel: 'Fine gravel',
  rocks: 'Rocks',
  ground: 'Ground',
  wood: 'Wood',
  unpaved: 'Unpaved'
};

export const locationUsages = {
  pedestrian: "Pedestrian",
  biking: "Biking",
  dogs: "Dogs",
  horseback_riding: "Horseback riding",
  wheelchair: "Wheelchair",
  hov: "HOV",
  atv: "ATV"
};

export const pointLocationTypes = [
  'trailhead',
  'parking_lot',
  'ranger_station',
  'drinking_water',
  'bathroom',
  // 'campground',
  'trash',
  'recycle',
  // 'landmark', // split this up?
  'information',
  // 'ski_lift',
  // 'pay_station',
];

export const alertStatuses = [
  'active',
  'inactive',
];

export const alertTypes = [
  "closure",
  "fire",
  "flood",
  "hazard",
  "lightning",
  "rain",
  "snow",
  "wildlife",
  "wind",
];

export const pointLocationTypeAttributes = {
  trailhead: [{key: 'description', name: 'Description', type: 'string'}],
  parking_lot: [{key: 'description', name: 'Description', type: 'string'}],
  ranger_station:[
    {key: 'details', name: 'Details', type: 'string'},
    {key: 'phone', name: 'Phone', type: 'string'},
    {key: 'website', name: 'Website', type: 'string'}],
  trash:[{key: 'details', name: 'Details', type: 'string'}],
  recycle:[{key: 'details', name: 'Details', type: 'string'}],
  bathroom:[{key: 'details', name: 'Details', type: 'string'}],
  information:[{key: 'details', name: 'Details', type: 'string'}],
  drinking_water:[{key: 'details', name: 'Details', type: 'string'}],

  alert: [
    {key: 'status', name: 'Status', type: 'select', values: alertStatuses},
    {key: 'type', name: 'Type', type: 'select', values: alertTypes},
    {key: 'summary', name: 'Summary', type: 'string'},
    {key: 'description', name: 'Description', type: 'string'},
    {key: 'end_time', name: 'Expiration', type: 'time'}
  ]
};

export const trailLocationTypeAttributes = {
  trail: [
    // {key: 'trail_type', name: 'Trail type', type: 'select', values: ['']},
    {key: 'surface_type', name: 'Surface Type', type: 'select', values: Object.keys(trailSurfaceTypes)},
  ]
};

export const hiddenLocationAttributes = [
  'id',
  'location_id',
  'user_id',
  'createdAt',
  'updatedAt',
  'user'
];
