import { PathLayer } from "deck.gl";

export const renderTrailLayer = (props) => {
  const { data, onHover, selected } = props;

  const trailLayer = [new PathLayer({
    id: "path-layer",
      data: data,
      pickable: true,
      rounded: true,
      widthScale: 1,
      widthMaxPixels: 7,
      widthMinPixels: 2,
      getPath: (d) => d.path,
      getColor: (d) => d.color,
      getWidth: (d) => 5,
      onHover,
  })];

  // const layers = [
  //   layersToggle.showTrailLayer ? new PathLayer({
  //     id: "path-layer",
  //     data: data,
  //     pickable: true,
  //     rounded: true,
  //     widthScale: 1,
  //     widthMaxPixels: 7,
  //     widthMinPixels: 2,
  //     getPath: (d) => d.path,
  //     getColor: (d) => d.color,
  //     getWidth: (d) => 5,
  //     onHover,
  //   }) : null,
  //   layersToggle.showReportLayer ? new IconLayer({
  //     id: "user-reports",
  //     pickable: true,
  //     data: [
  //       {
  //         name: 'Test',
  //         coordinates: [-105.306073, 40.012155]
  //       },
  //       {
  //         name: 'Test2',
  //         coordinates: [-105.301371, 40.015155]
  //       },
  //       {
  //         name: 'Test3',
  //         coordinates: [-105.293071, 39.99915]
  //       },
  //       {
  //         name: 'Test4',
  //         coordinates: [-105.29471, 40.00905]
  //       },
  //     ],
  //     iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
  //     iconMapping: ICON_MAPPING,
  //     getIcon: d => 'marker',

  //     sizeScale: 15,
  //     getPosition: d => d.coordinates,
  //     getSize: d => 3,
  //     getColor: d => [194, 94, 0],
  //     onHover,
  //   }) : null,
  // ];

  // let trailLayers = [trailLayer];

  if (selected) {
    trailLayer.unshift(
      new PathLayer({
        id: "selected-path-layer",
        data: selected,
        pickable: true,
        rounded: true,
        widthScale: 14,
        widthMaxPixels: 18,
        widthMinPixels: 4,
        getPath: (d) => d.path,
        getColor: [71, 157, 255],
      })
    );
  }

  return trailLayer;
};

export default renderTrailLayer;
