import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as actions from "../../../store/actions";
import { AssetToolbar, AssetTable, AssetMap } from "../components";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  map: {
    height: "50%",
    // width:'100%',
    zIndex: 50,
    position: "relative",
  },
  mapActionBar: {
    // display: 'inline-block',
    width: "100%",
    position: "absolute",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginRight: theme.spacing(4),
    zIndex: 100,
  },
}));

const Assets = (props) => {
  const classes = useStyles();

  useEffect((props) => {
    if (!props.assets) {
      props.getAssets();
    }
    return;
  }, []);

  // const [Asset] = useState(mockData);
  // console.log(Asset)
  let assets = (
    <div className={classes.root}>
      <p>Loading...</p>
    </div>
  );

  const [liveAssets, setLiveAssets] = useState(null);
  // const [selectedAsset, setSelectedAsset] = useState([]);

  // const handleSelectOne = (event, id) => {
  //   const selectedIndex = selectedAsset.indexOf(id);
  //   let newSelectedAsset = [];

  //   if (selectedIndex === -1) {
  //     newSelectedAsset = newSelectedAsset.concat(selectedAsset, id);
  //   } else if (selectedIndex === 0) {
  //     newSelectedAsset = newSelectedAsset.concat(selectedAsset.slice(1));
  //   } else if (selectedIndex === selectedAsset.length - 1) {
  //     newSelectedAsset = newSelectedAsset.concat(selectedAsset.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelectedAsset = newSelectedAsset.concat(
  //       selectedAsset.slice(0, selectedIndex),
  //       selectedAsset.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelectedAsset(newSelectedAsset);
  // };

  if (props.assets) {
    if (!liveAssets) {
      setLiveAssets(props.assets);
    }
  }

  const searchAssetHandler = (event) => {
    const searchEl = event.target.value;
    const lowerCaseSearchEl = searchEl;
    const filteredData = props.assets.filter((el) =>
      el.name.toLowerCase().includes(lowerCaseSearchEl)
    );
    setLiveAssets(filteredData);
    return filteredData;
  };

  const toggleMap = () => props.switchShowMap();

  if (liveAssets) {
    // console.log(liveAssets)
    if (!props.showMap) {
      assets = (
        <div className={classes.root}>
          <div>
            <AssetToolbar
              searchAssetHandler={searchAssetHandler}
              switchMap={toggleMap}
            />
          </div>
          <div className={classes.content}>
            <AssetTable Asset={liveAssets} />
          </div>
        </div>
      );
    } else {
      assets = (
        <div>
          <div className={classes.map}>
            <AssetMap
              data={liveAssets}
              // selection={selectedAsset}
            >
              <div className={classes.mapActionBar}>
                <AssetToolbar
                  searchAssetHandler={searchAssetHandler}
                  switchMap={toggleMap}
                />
              </div>
            </AssetMap>
          </div>
        </div>
      );
    }
  }

  return <div> {assets}</div>;
};

Assets.propTypes = {
  assets: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    loading: state.assets.loading,
    error: state.assets.error,
    assets: state.assets.assets,
    showMap: state.map.showMap,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAssets: () => dispatch(actions.getAssets()),
    switchShowMap: () => dispatch(actions.switchShowMap()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
