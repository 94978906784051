import React, { useState } from "react";
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { pointLocationTypes, pointLocationTypeAttributes, trailLocationTypeAttributes, locationUsages } from "../../../utilities/mapConstants";
import { snakeToWords } from "../../../utilities/textManipulation";
import * as actions from "../../../store/actions/index";

import {
  Button,
  CardActions,
  TextField,
  Typography,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  FormLabel,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 80,
    marginLeft: theme.spacing(3)
  },
  cardHeader: {
    paddingBottom: 0,
  },
  dialogForm: {
    margin: theme.spacing(1),
    '& .MuiFormControl-root': {
      minWidth: "60px",
    }
  },
  formComponent: {
    width: '100%',
    margin: theme.spacing(1),
  },
  formComponentSplit: {
    display: 'flex'
  },
}));

const LocationDetail = (props) => {
  const { onClose, data } = props;
  const classes = useStyles();

  const [locationData, setLocationData] = useState({
    // name: data.name,
    // type: data.type,
    // // geom: { type: "Point", coordinates: locationCoordinates },
    // path: data.path,
    // attributes: data.attributes,
    // usages: data.usages,
    ...data,
    geom: { type: data.type == 'trail' ? "LineString" : "Point", coordinates: data.path },
  });

  const [isEditing, setIsEditing] = useState(false);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const getTypeList = (type) => {
    let returnElements = [];
    switch (type) {
      case 'point':
        pointLocationTypes.map((type) => 
          returnElements.push(<MenuItem value={type}>{type}</MenuItem>)
        )
        break;
      case 'alert':
        returnElements.push(<MenuItem value={'alert'}>Alert</MenuItem>);
        break;
      default:
        break;
    }
    return returnElements;
  };

  const handleEditLocationForm = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const oldCoordinates = locationData.geom.coordinates;
    let newCoordinates = oldCoordinates;
    if (name === 'lat') {
      newCoordinates[0] = value;
      setLocationData({
        ...locationData, geom: {
        ...locationData.geom,
        coordinates: newCoordinates
      }});
    }
    else if (name === 'long') {
      newCoordinates[1] = value;
      setLocationData({
        ...locationData, geom: {
        ...locationData.geom,
        coordinates: newCoordinates
      }});
    }
    else {
      setLocationData({
        ...locationData,
        [name]: value
      });
    }
    console.log(locationData)
  };

  const handleEditLocationAttributes = (event) => {
    setLocationData({
      ...locationData,
      attributes: {
        ...locationData.attributes,
        [event.target.name]: event.target.value
      }
    });
  };

  const handleEditLocationUsages = (event) => {
    setLocationData({
      ...locationData,
      usages: {
        ...locationData.usages,
        [event.target.name]: event.target.checked ? 'allowed' : null
      }
    });
  };

  const getEditLocationAttributeElement = (attribute) => {
    let returnElement;
    const elementType = attribute.type;
    switch (elementType) {
      case 'boolean':
        returnElement = <FormControlLabel
          className={classes.formComponent}
          control={<Checkbox checked={locationData.attributes[attribute.key]} disabled={!isEditing} onChange={handleEditLocationAttributes} name={attribute.key} checkedIcon={<CheckBoxIcon className={classes.checkbox}/>}/>}
          label={attribute.name}/>
        break;
      case 'string':
        returnElement = <TextField
          className={classes.formComponent}
          label={attribute.name}
          name={attribute.key}
          value={locationData?.attributes?.[attribute.key] ? locationData.attributes[attribute.key] : null}
          disabled={!isEditing}
          onChange={handleEditLocationAttributes}/>
        break;
      case 'select':
        returnElement = 
        <FormControl className={classes.formComponent}>
          <InputLabel id="add-location-attribute-type">{attribute.name}</InputLabel>
          <Select
            className={classes.formComponent}
            labelId="add-location-attribute-type"
            id={"location-attribute-" + attribute.key}
            name={attribute.key}
            value={locationData?.attributes?.[attribute.key] ? locationData.attributes[attribute.key] : null}
            disabled={!isEditing}
            onChange={handleEditLocationAttributes}
          >
            {attribute.values.map((type) => 
              <MenuItem value={type}>{snakeToWords(type)}</MenuItem>
            )}
          </Select>
        </FormControl>
        break;
      default:
        break;
    }
    return returnElement;
  };

  const confirmEditLocation = () => {
    //TODO: update data in the selection panel / redux assets
    delete locationData.path;
    delete locationData.coordinates;
    delete locationData.color;
    console.log(locationData);
    switch (locationData.type) {
      case 'alert':
        props.updateAlert(locationData);
        break;
      case 'report':
        locationData.report_attribute = {
          note: locationData.note,
          status: locationData.status,
          type: locationData.report_type
        };
        props.updateReport(locationData);
        break;
      case 'point_location':
        locationData.type = locationData.point_type;
        props.updatePoint(locationData);
        break;
      case 'trail':
        props.updateTrail(locationData);
        break;
      default:
        break;
    }
    setLocationData({});
    onClose();
  };

  const getFormForLocationType = (locationType, data) => {
    let returnForm;
    switch (locationType) {
      case 'report':
        returnForm = 
        <form className={classes.dialogForm}>
          <TextField
            label="Name"
            name="name"
            value={data["name"]}
            disabled={true}
            className={classes.formComponent}/>
          <div className={classes.formComponentSplit}>
            <TextField
              label="Latitude"
              name="lat"
              type="number"
              value={locationData.path[0]}
              disabled={true}
              className={classes.formComponent}/>
            <TextField
              label="Longitude"
              name="long"
              type="number"
              value={locationData.path[1]}
              disabled={true}
              className={classes.formComponent}/>
          </div>
          <Typography style={{ margin: 8, }}>Note: {data.note}</Typography>
          <FormControl className={classes.formComponent}>
            <InputLabel id="edit-report-status">Status</InputLabel>
            <Select
              labelId="edit-report-status"
              id="report-status-select"
              name="status"
              value={locationData.status}
              disabled={!isEditing}
              onChange={handleEditLocationForm}
            >
              <MenuItem value={'confirmed'}>Confirmed</MenuItem>
              <MenuItem value={'unconfirmed'}>Unonfirmed</MenuItem>
            </Select>
          </FormControl>
        </form>
        break;
      
      case 'alert':
        returnForm =
        <form className={classes.dialogForm}>
          <TextField
            label="Name"
            name="name"
            value={data["name"]}
            disabled={!isEditing}
            onChange={handleEditLocationForm}
            className={classes.formComponent}/>
          <div className={classes.formComponentSplit}>
            <TextField
              label="Latitude"
              name="lat"
              type="number"
              value={locationData.geom.coordinates[0]}
              disabled={!isEditing}
              onChange={handleEditLocationForm}
              className={classes.formComponent}/>
            <TextField
              label="Longitude"
              name="long"
              type="number"
              value={locationData.geom.coordinates[0]}
              disabled={!isEditing}
              onChange={handleEditLocationForm}
              className={classes.formComponent}/>
          </div>
          <FormControl className={classes.formComponent}>
            <InputLabel id="edit-alert-type">Type</InputLabel>
            <Select
              labelId="edit-alert-type"
              id="alert-type-select"
              name="type"
              value={locationData["type"]}
              onChange={handleEditLocationForm}
            >
              <MenuItem value={'alert'}>Alert</MenuItem>
            </Select>
          </FormControl>
          <Collapse in={Boolean(locationData["type"])} unmountOnExit>
            <FormControl>
              <FormGroup>
                {pointLocationTypeAttributes[locationData["type"]] && pointLocationTypeAttributes[locationData["type"]].length
                  ? pointLocationTypeAttributes[locationData["type"]].map((attribute, index) =>
                  getEditLocationAttributeElement(attribute)
                ) : null}
              </FormGroup>
            </FormControl>
          </Collapse>
        </form>
        break;

        case 'point_location':
        returnForm =
        <form className={classes.dialogForm}>
          <TextField
            label="Name"
            name="name"
            value={data["name"]}
            disabled={!isEditing}
            onChange={handleEditLocationForm}
            className={classes.formComponent}/>
          <div className={classes.formComponentSplit}>
            <TextField
              label="Latitude"
              name="lat"
              type="number"
              value={locationData.geom.coordinates[0]}
              disabled={!isEditing}
              onChange={handleEditLocationForm}
              className={classes.formComponent}/>
            <TextField
              label="Longitude"
              name="long"
              type="number"
              value={locationData.geom.coordinates[0]}
              disabled={!isEditing}
              onChange={handleEditLocationForm}
              className={classes.formComponent}/>
          </div>
          <FormControl className={classes.formComponent}>
            <InputLabel id="edit-location-type">Type</InputLabel>
            <Select
              labelId="edit-location-type"
              id="location-type-select"
              name="type"
              value={locationData["point_type"]}
              disabled={true}
              // onChange={handleEditLocationForm}
            >
              {getTypeList('point')}
            </Select>
          </FormControl>
          <Collapse in={Boolean(locationData["point_type"])} unmountOnExit>
            <FormControl>
              <FormGroup>
                {pointLocationTypeAttributes[locationData["point_type"]] && pointLocationTypeAttributes[locationData["point_type"]].length
                  ? pointLocationTypeAttributes[locationData["point_type"]].map((attribute, index) =>
                  getEditLocationAttributeElement(attribute)
                ) : null}
              </FormGroup>
            </FormControl>
          </Collapse>
        </form>
        break;

        case 'trail':
        returnForm =
        <form className={classes.dialogForm}>
          <TextField
            label="Name"
            name="name"
            value={data["name"]}
            disabled={!isEditing}
            onChange={handleEditLocationForm}
            className={classes.formComponent}/>
          <FormControl className={classes.formComponent}>
            <InputLabel id="edit-location-type">Type</InputLabel>
            <Select
              labelId="edit-location-type"
              id="location-type-select"
              name="type"
              value={locationData["type"]}
              disabled={true}
              // onChange={handleEditLocationForm}
            >
              <MenuItem value={locationData["type"]}>Trail</MenuItem>
            </Select>
          </FormControl>
          <Collapse in={Boolean(locationData["type"])} unmountOnExit>
            <div className={classes.formComponentSplit}>
              <FormControl className={classes.formComponent}>
                <FormGroup>
                  {trailLocationTypeAttributes[locationData["type"]] && trailLocationTypeAttributes[locationData["type"]].length
                    ? trailLocationTypeAttributes[locationData["type"]].map((attribute, index) =>
                    getEditLocationAttributeElement(attribute)
                  ) : null}
                </FormGroup>
              </FormControl>
              <FormControl className={classes.formComponent} component='fieldset' margin='dense'>
                <FormLabel component='legend'>Usages</FormLabel>
                {locationData.usages
                  ? Object.keys(locationData.usages).map((keyName, index) =>
                      keyName !== "id" &&
                      keyName !== "location_id" ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={keyName}
                              checked={locationData.usages[keyName] === 'allowed'}
                              disabled={!isEditing}
                              onChange={handleEditLocationUsages}
                              color='primary'
                              size='small'
                            />
                          }
                          label={locationUsages[keyName]}
                        />
                      ) : null
                    )
                  : null}
              </FormControl>
            </div>
          </Collapse>
        </form>
        break;
      default:
        break;
    }
    return returnForm;
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <CardHeader
          className={classes.cardHeader}
          title={locationData.name ? locationData.name : "Name"}/>
        {getFormForLocationType(locationData.type, locationData)}
      </CardContent>

      <CardActions disableSpacing>
        <Button onClick={onClose}>Close</Button>
        <IconButton onClick={toggleEditing} aria-label="toggle-edit">
          {isEditing ? (<ClearIcon/>) : (<EditIcon/>)}
        </IconButton>
        {isEditing ? (
          <IconButton onClick={confirmEditLocation} aria-label="confirm">
            <CheckIcon/>
          </IconButton>
        ) : null}
      </CardActions>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.users.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePoint: (object) => dispatch(actions.updatePoint(object)),
    updateAlert: (object) => dispatch(actions.updateAlert(object)),
    updateReport: (object) => dispatch(actions.updateReport(object)),
    updateTrail: (object) => dispatch(actions.updateTrail(object)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetail);
