import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../utility";

const initialState = {
  error: null,
  loading: false,
  userActivities: null,
  adding: false,
  selection: null,
};

const getActivitiesStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getActivitiesSuccess = (state, action) => {
  return updateObject(state, {
    userActivities: action.userActivities,
    error: null,
    loading: false,
  });
};

const getActivitiesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const createActivityStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const createActivitySuccess = (state, action) => {
  return updateObject(state, {
    userActivities: action.userActivities,
    error: null,
    loading: false,
  });
};

const createActivityFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const updateActivityStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const updateActivitySuccess = (state, action) => {
  return updateObject(state, {
    userActivities: action.userActivities,
    error: null,
    loading: false,
  });
};

const updateActivityFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const deleteActivityStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const deleteActivitySuccess = (state, action) => {
  return updateObject(state, {
    userActivities: action.userActivities, //TODO: check if this needs to be adjusted to just append to the userActivity state.
    error: null,
    loading: false,
  });
};

const deleteActivityFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVITIES_START:
      return getActivitiesStart(state, { error: null, loading: true });
    case actionTypes.GET_ACTIVITIES_SUCCESS:
      return getActivitiesSuccess(state, action);
    case actionTypes.GET_ACTIVITIES_FAIL:
      return getActivitiesFail(state, action);
    case actionTypes.CREATE_ACTIVITY_START:
      return createActivityStart(state, { error: null, loading: true });
    case actionTypes.CREATE_ACTIVITY_SUCCESS:
      return createActivitySuccess(state, action);
    case actionTypes.CREATE_ACTIVITY_FAIL:
      return createActivityFail(state, action);
    case actionTypes.UPDATE_ACTIVITY_START:
      return updateActivityStart(state, { error: null, loading: true });
    case actionTypes.UPDATE_ACTIVITY_SUCCESS:
      return updateActivitySuccess(state, action);
    case actionTypes.UPDATE_ACTIVITY_FAIL:
      return updateActivityFail(state, action);
    case actionTypes.DELETE_ACTIVITY_START:
      return deleteActivityStart(state, { error: null, loading: true });
    case actionTypes.DELETE_ACTIVITY_SUCCESS:
      return deleteActivitySuccess(state, action);
    case actionTypes.DELETE_ACTIVITY_FAIL:
      return deleteActivityFail(state, action);
    default:
      return state;
  }
};

export default activityReducer;
