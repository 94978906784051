import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../utility";

const initialState = {
  error: null,
  isLoading: false,
  currentOrg: null,
  users: [],
  defaultOrg: null, //charlie: we need to add this to db
  orgs: null, //charlie: this should just be an array of org ids and org names
  adding: false,
};

const getOrgStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const getOrgSuccess = (state, action) => {
  return updateObject(state, {
    currentOrg: action.org, //TODO: users is now in currentOrg and users (flattening redux state will fix this)
    users: action.org.users,
    error: null,
    isLoading: false,
  });
};

const getOrgFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const getOrgsStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const getOrgsSuccess = (state, action) => {
  return updateObject(state, {
    orgs: action.organizations,
    error: null,
    isLoading: false,
  });
};

const getOrgsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const createOrgStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const createOrgSuccess = (state, action) => {
  return updateObject(state, {
    orgs: action.orgs, //TODO: check if this needs to be adjusted to just append to the orgs state.
    error: null,
    isLoading: false,
  });
};

const createOrgFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const updateOrgStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const updateOrgSuccess = (state, action) => {
  return updateObject(state, {
    orgs: action.orgs, //TODO: check if this needs to be adjusted to just append to the orgs state.
    error: null,
    isLoading: false,
  });
};

const updateOrgFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const deleteOrgStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const deleteOrgSuccess = (state, action) => {
  return updateObject(state, {
    orgs: action.orgs, //TODO: check if this needs to be adjusted to just append to the orgs state.
    error: null,
    isLoading: false,
  });
};

const deleteOrgFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const clearOrg = (state) => {
  return updateObject(state, {
    currentOrg: null,
    isLoading: false,
  });
};

const updateTenantStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const updateTenantSuccess = (state, action) => {
  return updateObject(state, {
    // orgs: action.orgs, //TODO: check if this needs to be adjusted to just append to the orgs state.
    error: null,
    isLoading: false,
  });
};

const updateTenantFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const addTenantStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const addTenantSuccess = (state, action) => {
  return updateObject(state, {
    // orgs: action.orgs, //TODO: check if this needs to be adjusted to just append to the orgs state.
    error: null,
    isLoading: false,
  });
};

const addTenantFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const deleteTenantStart = state => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const deleteTenantSuccess = (state, action) => {
  return updateObject(state, {
    // orgs: action.orgs, //TODO: check if this needs to be adjusted to just append to the orgs state.
    error: null,
    isLoading: false,
  });
};

const deleteTenantFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_START:
      return getOrgStart(state, { error: null, isLoading: true });
    case actionTypes.GET_ORG_SUCCESS:
      return getOrgSuccess(state, action);
    case actionTypes.GET_ORG_FAIL:
      return getOrgFail(state, action);
    case actionTypes.GET_ORGS_START:
      return getOrgsStart(state, { error: null, isLoading: true });
    case actionTypes.GET_ORGS_SUCCESS:
      return getOrgsSuccess(state, action);
    case actionTypes.GET_ORGS_FAIL:
      return getOrgsFail(state, action);
    case actionTypes.CREATE_ORG_START:
      return createOrgStart(state, { error: null, isLoading: true });
    case actionTypes.CREATE_ORG_SUCCESS:
      return createOrgSuccess(state, action);
    case actionTypes.CREATE_ORG_FAIL:
      return createOrgFail(state, action);
    case actionTypes.UPDATE_ORG_START:
      return updateOrgStart(state, { error: null, isLoading: true });
    case actionTypes.UPDATE_ORG_SUCCESS:
      return updateOrgSuccess(state, action);
    case actionTypes.UPDATE_ORG_FAIL:
      return updateOrgFail(state, action);
    case actionTypes.DELETE_ORG_START:
      return deleteOrgStart(state, { error: null, isLoading: true });
    case actionTypes.DELETE_ORG_SUCCESS:
      return deleteOrgSuccess(state, action);
    case actionTypes.DELETE_ORG_FAIL:
      return deleteOrgFail(state, action);
    case actionTypes.CLEAR_ORG:
      return clearOrg(state, { currentOrg: null, isLoading: false });
    case actionTypes.UPDATE_TENANT_START:
      return updateTenantStart(state, { error: null, isLoading: true });
    case actionTypes.UPDATE_TENANT_SUCCESS:
      return updateTenantSuccess(state, action);
    case actionTypes.UPDATE_TENANT_FAIL:
      return updateTenantFail(state, action);
    case actionTypes.ADD_TENANT_START:
      return addTenantStart(state, { error: null, isLoading: true });
    case actionTypes.ADD_TENANT_SUCCESS:
      return addTenantSuccess(state, action);
    case actionTypes.ADD_TENANT_FAIL:
      return addTenantFail(state, action);
    case actionTypes.DELETE_TENANT_START:
      return deleteTenantStart(state, { error: null, isLoading: true });
    case actionTypes.DELETE_TENANT_SUCCESS:
      return deleteTenantSuccess(state, action);
    case actionTypes.DELETE_TENANT_FAIL:
      return deleteTenantFail(state, action);
    default:
      return state;
  }
};

export default orgReducer;
