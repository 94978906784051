import { PathLayer } from "deck.gl";

export const renderLayers = (props) => {
  const { data, onHover, selected } = props;

  const layers = [
    new PathLayer({
      id: "path-layer",
      data: data,
      pickable: true,
      rounded: true,
      widthScale: 1,
      widthMaxPixels: 7,
      widthMinPixels: 2,
      getPath: (d) => d.path,
      getColor: (d) => d.color,
      getWidth: (d) => 5,
      onHover,
    }),
  ];

  if (selected) {
    layers.unshift(
      new PathLayer({
        id: "selected-path-layer",
        data: selected,
        pickable: true,
        rounded: true,
        widthScale: 14,
        widthMaxPixels: 18,
        widthMinPixels: 4,
        getPath: (d) => d.path,
        getColor: [235, 219, 52],
      })
    );
  }

  return layers;
};

export default renderLayers;
