import { PathLayer } from "deck.gl";

export const renderUserActivityLayer = (props) => {
  const { data, onHover, selected } = props;

  const userActivityLayer = [new PathLayer({
    id: "user-data",
      data: data,
      pickable: true,
      rounded: true,
      widthScale: 1,
      widthMaxPixels: 7,
      widthMinPixels: 2,
      getPath: (d) => d.path,
      getColor: (d) => d.color,
      getWidth: (d) => 5,
      onHover,
  })];

  if (selected) {
    userActivityLayer.unshift(
      new PathLayer({
        id: "selected-user-data",
        data: selected,
        pickable: true,
        rounded: true,
        widthScale: 14,
        widthMaxPixels: 18,
        widthMinPixels: 4,
        getPath: (d) => d.path,
        getColor: [71, 157, 255],
      })
    );
  }

  return userActivityLayer;
};

export default renderUserActivityLayer;
