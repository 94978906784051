import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: "flex",
  },
  avatar: {
    marginLeft: "auto",
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
}));

const AccountProfile = (props) => {
  const { className, user, ...rest } = props;

  const classes = useStyles();

  const tempUser = {
    name: "Aaron Kennedy",
    city: "Los Angeles",
    country: "USA",
    timezone: "GTM-7",
    avatar: "/images/avatars/avatar_0.png",
  };

  return (
    <>
      {user ? (
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardContent>
            <div className={classes.details}>
              <div>
                <Typography gutterBottom variant="h2">
                  {user.first_name} {user.last_name}
                </Typography>
                <Typography
                  className={classes.locationText}
                  color="textSecondary"
                  variant="body1"
                >
                  {tempUser.city}, {tempUser.country}
                </Typography>
                <Typography
                  className={classes.dateText}
                  color="textSecondary"
                  variant="body1"
                >
                  {moment().format("hh:mm A")} ({tempUser.timezone})
                </Typography>
              </div>
              <Avatar className={classes.avatar} src={tempUser.avatar} />
            </div>
            <div className={classes.progress}>
              <Typography variant="body1">Profile Completeness: 70%</Typography>
              <LinearProgress value={70} variant="determinate" />
            </div>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              className={classes.uploadButton}
              color="primary"
              variant="text"
            >
              Upload picture
            </Button>
            <Button variant="text">Remove picture</Button>
          </CardActions>
        </Card>
      ) : null}
    </>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    user: state.users.currentUser,
    // authenticated: state.auth.authenticated,
    // loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfile);
