import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  chip: {
    padding: 0,
    maxWidth: 345,
    maxHeight: 25,
    position: "absolute",
    zIndex: 110,
    bottom: 0,
    marginLeft: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    marginLeft: "8px",
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: "#eee",
  },
  cardHeader: {
    padding: 2,
  },
}));

const HoverCard = (props) => {
  const classes = useStyles();
  const object = props.object;

  return (
    <Chip
      className={classes.chip}
      style={{ left: props.horizontal, top: props.vertical }}
      size="small"
      avatar={<Avatar alt={object.iconUrl} src={object.iconUrl} />}
      label={object.name}
      color="secondary"
    />
  );
};

export default HoverCard;
