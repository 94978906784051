import React, { forwardRef, useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/styles";
import UserPermissions from "./UserPermissions";
import MaterialTable from "material-table";
import { Modal, Paper, Typography, TextField, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import * as actions from "../../../../store/actions";

// import AddBox from '@material-ui/icons/AddBox';
// import ArrowDownward from '@material-ui/icons/ArrowDownward';
// import Check from '@material-ui/icons/Check';
// import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import ChevronRight from '@material-ui/icons/ChevronRight';
// import Clear from '@material-ui/icons/Clear';
// import DeleteOutline from '@material-ui/icons/DeleteOutline';
// import Edit from '@material-ui/icons/Edit';
// import FilterList from '@material-ui/icons/FilterList';
// import FirstPage from '@material-ui/icons/FirstPage';
// import LastPage from '@material-ui/icons/LastPage';
// import Remove from '@material-ui/icons/Remove';
// import SaveAlt from '@material-ui/icons/SaveAlt';
// import Search from '@material-ui/icons/Search';
// import ViewColumn from '@material-ui/icons/ViewColumn';

// const tableIcons = {
//   Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//   Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//   Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//   DetailPanel: forwardRef((props, ref) => (
//     <ChevronRight {...props} ref={ref} />
//   )),
//   Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//   Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//   Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   PreviousPage: forwardRef((props, ref) => (
//     <ChevronLeft {...props} ref={ref} />
//   )),
//   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
//   ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
//   ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
// };

const styles = (theme) => {
  return {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(4),
      width: "60%",
      maxWidth: "400px",
    },
  };
};

const columns = [
  { title: "First Name", field: "firstName" },
  { title: "Last Name", field: "lastName" },
  { title: "Email", field: "email" },
  { title: "Admin", field: "isAdmin", type: "boolean" },
];

const UserTable = (props) => {
  const {
    userId,
    users,
    allUsers,
    isLoading,
    isAdmin,
    classes,
    addTenant,
    deleteTenant,
    currentOrg,
  } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const [userToAdd, setUserToAdd] = useState(null);

  const handleChange = (e, values) => {
    setUserToAdd(values);
  };

  const addUser = () => {
    if (userToAdd) {
      const tenant = {
        user_id: userToAdd.id,
        organization_id: currentOrg.id,
        admin: false,
      };
      addTenant(tenant, currentOrg.id);
    }
    setModalOpen(false);
  };

  // const deleteUser = oldData => {
  //   console.log(oldData);
  //   deleteTenant(oldData, currentOrg.id);
  // };

  const detailPanel = isAdmin
    ? [
        {
          tooltip: "Show Details",
          render: (rowData) => {
            return <UserPermissions rowData={rowData} />;
          },
        },
      ]
    : [];

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        title=""
        isLoading={isLoading}
        // icons={tableIcons}
        columns={columns}
        data={users}
        // editable={{
        //   // isEditable: rowData => isAdmin === true,
        //   isDeletable: rowData => isAdmin === true && userId !== rowData.userId,
        //   // onRowAdd: newData => addUser(newData),
        //   // onRowUpdate: oldData.isLocked
        //   //   ? (newData, oldData) =>
        //   //       this.props.editInventory(newData, oldData)
        //   //   : null,
        //   onRowDelete: oldData => deleteTenant(oldData),
        // }}
        editable={{
          isDeletable: rowData => isAdmin === true && userId !== rowData.userId,
          onRowDelete: oldData => deleteTenant(oldData),
        }}
        detailPanel={detailPanel}
        options={{
          actionsColumnIndex: -1,
          cellStyle: {
            borderBottom: "1px solid #e0e0e0",
            padding: "1rem",
            fontFamily: "Roboto",
          },
        }}
        actions={[
          {
            icon: "add",
            tooltip: "Add User",
            isFreeAction: true,
            onClick: (event) => setModalOpen(true),
          },
        ]}
        localization={{
          body: {
            editRow: {
              deleteText:
                "Are you sure you want to delete this user from your org?", //i18n
            },
            emptyDataSourceMessage: "No users in org", //i18n
            addTooltip: "Add User", //i18n
          },
        }}
      />
      <Modal
        className={classes.modal}
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Paper className={classes.paper}>
          <Autocomplete
            options={allUsers}
            getOptionLabel={(option) => option.email}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField {...params} label="Add a user" variant="outlined" />
            )}
          ></Autocomplete>
          <Button onClick={addUser}>Add</Button>
        </Paper>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentOrg: state.orgs.currentOrg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTenant: (tenant, orgId) => dispatch(actions.addTenant(tenant, orgId)),
    deleteTenant: (tenant, orgId) => dispatch(actions.deleteTenant(tenant, orgId)),
  };
};

export default compose(
  withStyles(styles, { name: "UserTable" }),
  connect(mapStateToProps, mapDispatchToProps)
)(UserTable);
