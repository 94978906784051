export const mapUsageIcons = (field) => {
  switch (field) {
    case "pedestrian":
      field = String.fromCodePoint(0x1f6b6);
      break;
    case "biking":
      field = String.fromCodePoint(0x1f6b4);
      break;
    case "dogs":
      field = String.fromCodePoint(0x1f415);
      break;
    case "horseback_riding":
      field = String.fromCodePoint(0x1f40e);
      break;
    case "hov":
      field = String.fromCodePoint(0x1f699);
      break;
    case "atv":
      field = String.fromCodePoint(0x1f6fa);
      break;
    case "wheelchair":
      field = String.fromCodePoint(0x267f);
      break;
    default:
      break;
  }
  return field;
};
