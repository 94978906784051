import { API } from "aws-amplify";

import * as actionTypes from "./actionTypes";
import config from "../../config";

export const getUserStart = () => {
  return {
    type: actionTypes.GET_USER_START,
  };
};

export const getUserSuccess = (user) => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    user: user,
  };
};

export const getUserFail = (err) => {
  return {
    type: actionTypes.GET_USER_FAIL,
    error: err,
  };
};

// export const getUser = cognitoId => {
//   return dispatch => {
//     dispatch(getUserStart());
//     const myInit = {
//       headers: { 'x-api-key': 'fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c' },
//     };
//     API.get('outway', '/users/' + cognitoId, myInit)
//       .then(res => {
//         console.log(res);
//         dispatch(getUserSuccess(res.response[0]));
//       })
//       .catch(err => console.log(err));
//   };
// };

export const getUser = (cognitoId) => (dispatch) => {
  dispatch(getUserStart());
  const myInit = {
    headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
  };
  // dispatch(getUserSuccess({ user: 'charlie', organizations: [{ tenants: { admin: true } }] }));

  API.get("outway", "/users/" + cognitoId, myInit)
    .then((res) => {
      dispatch(getUserSuccess(res.response[0]));
    })
    .catch((err) => {
      console.log(err);
      // TODO: redirect to logout
    });
};

export const getUsersStart = () => {
  return {
    type: actionTypes.GET_USERS_START,
  };
};

export const getUsersSuccess = (users) => {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    users: users,
  };
};

export const getUsersFail = (err) => {
  return {
    type: actionTypes.GET_USERS_FAIL,
    error: err,
  };
};

export const getUsers = () => {
  return (dispatch) => {
    dispatch(getUsersStart());
    const myInit = {
      // OPTIONAL
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
    };
    API.get("outway", "/users", myInit)
      .then((res) => {
        console.log(res);
        dispatch(getUsersSuccess(res.response));
      })
      .catch((err) => console.log(err));
  };
};

export const createUserStart = () => {
  return {
    type: actionTypes.CREATE_USER_START,
  };
};

export const createUserSuccess = (assets) => {
  return {
    type: actionTypes.CREATE_USER_SUCCESS,
    assets: assets,
  };
};

export const createUserFail = (err) => {
  return {
    type: actionTypes.CREATE_USER_FAIL,
    error: err,
  };
};

export const createUser = (data) => {
  return (dispatch) => {
    // console.log(config);
    dispatch(createUserStart());
    const myInit = {
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
      body: data,
    };
    console.log(myInit);
    API.post("outway", "/users", myInit)
      .then((res) => {
        console.log(res);
        dispatch(createUserSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START,
  };
};

export const updateUserSuccess = (assets) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    assets: assets,
  };
};

export const updateUserFail = (err) => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    error: err,
  };
};

export const updateUser = (object) => {
  return (dispatch) => {
    console.log(config);
    dispatch(updateUserStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.put("outway", "/users/" + object.id, myInit)
      .then((res) => {
        console.log(res);
        dispatch(updateUserSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const deleteUserStart = () => {
  return {
    type: actionTypes.DELETE_USER_START,
  };
};

export const deleteUserSuccess = (assets) => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    assets: assets,
  };
};

export const deleteUserFail = (err) => {
  return {
    type: actionTypes.DELETE_USER_FAIL,
    error: err,
  };
};

export const deleteUser = (object) => {
  return (dispatch) => {
    console.log(config);
    dispatch(deleteUserStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.del('outway', '/users/' + object.id, myInit)
      .then(res => {
        console.log(res);
        dispatch(deleteUserSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const clearUser = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_USER,
    });
  };
};
