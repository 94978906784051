/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";

import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, Button, colors } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    width: "100%",
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightMedium,
  },
  buttonOpen: {
    width: "168px",
  },
  buttonClosed: {
    width: "44px",
    minWidth: "24px"
  },
  currentPage: {
    backgroundColor:"#EBEBEB"
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
    marginLeft: "2px"
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, isOpen, currentUser, ...rest } = props;

  const classes = useStyles();

  const location = useLocation();
  
  return (
    <>
      <List {...rest} className={clsx(className, classes.root)}>
        {pages.map((page) => {
          const isAdmin = currentUser.organizations[0].tenants.admin;
          const isCurrentPage = location.pathname === page.href ? true : false;
          if (!page.requiresAdmin || (page.requiresAdmin && isAdmin)) {
            return (
              <ListItem
                className={classes.item}
                disableGutters
                key={page.title}
              >
                <Button
                  activeClassName={classes.active}
                  className={clsx(classes.button, {
                    [classes.buttonOpen]:isOpen, 
                    [classes.buttonClosed]: !isOpen, 
                    [classes.currentPage]: isCurrentPage,}) }
                  component={CustomRouterLink}
                  to={page.href} 
                >
                  <div className={classes.icon}>{page.icon}</div>
                  { isOpen ? page.title : ""}
                </Button>
              </ListItem>
            );
          }
        })}
      </List>
    </>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.users.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNav);
