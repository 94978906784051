import { API } from "aws-amplify";

import * as actionTypes from "./actionTypes";
import config from "../../config";

export const getReportsStart = () => {
  return {
    type: actionTypes.GET_REPORTS_START,
  };
};

export const getReportsSuccess = (reports) => {
  return {
    type: actionTypes.GET_REPORTS_SUCCESS,
    reports: reports,
  };
};

export const getReportsFail = (err) => {
  return {
    type: actionTypes.GET_REPORTS_FAIL,
    error: err,
  };
};

export const getReports = () => {
  return (dispatch) => {
    dispatch(getReportsStart());
    const myInit = {
      // OPTIONAL
      headers: {
        "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c",
      },
    };
    API.get("outway", "/reports", myInit)
      .then((res) => {
        console.log(res);
        dispatch(getReportsSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const createReportStart = () => {
  return {
    type: actionTypes.CREATE_REPORT_START,
  };
};

export const createReportSuccess = (assets) => {
  return {
    type: actionTypes.CREATE_REPORT_SUCCESS,
    assets: assets,
  };
};

export const createReportFail = (err) => {
  return {
    type: actionTypes.CREATE_REPORT_FAIL,
    error: err,
  };
};

export const createReport = (object) => {
  return (dispatch) => {
    console.log(config);
    dispatch(createReportStart());
    const myInit = {
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
      body: object,
    };
    API.post("outway", "/reports/", myInit)
      .then((res) => {
        console.log(res);
        dispatch(createReportSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const updateReportStart = () => {
  return {
    type: actionTypes.UPDATE_REPORT_START,
  };
};

export const updateReportSuccess = (assets) => {
  return {
    type: actionTypes.UPDATE_REPORT_SUCCESS,
    assets: assets,
  };
};

export const updateReportFail = (err) => {
  return {
    type: actionTypes.UPDATE_REPORT_FAIL,
    error: err,
  };
};

export const updateReport = (object) => {
  return (dispatch) => {
    // console.log(config);
    dispatch(updateReportStart());
    const myInit = {
      // OPTIONAL
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
      body: object, // OPTIONAL
    };
    API.put("outway", "/reports/" + object.id, myInit)
      .then((res) => {
        console.log(res);
        dispatch(updateReportSuccess(res));
        dispatch(getReports()); // TODO: optimize
      })
      .catch((err) => console.log(err));
  };
};

export const deleteReportStart = () => {
  return {
    type: actionTypes.DELETE_REPORT_START,
  };
};

export const deleteReportSuccess = (assets) => {
  return {
    type: actionTypes.DELETE_REPORT_SUCCESS,
    assets: assets,
  };
};

export const deleteReportFail = (err) => {
  return {
    type: actionTypes.DELETE_REPORT_FAIL,
    error: err,
  };
};

export const deleteReport = (object) => {
  return (dispatch) => {
    console.log(config);
    dispatch(deleteReportStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.del('outway', '/reports/' + object.id, myInit)
      .then(res => {
        console.log(res);
        dispatch(deleteReportSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};
