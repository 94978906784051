import React from "react";
import { Switch } from "react-router-dom";

import RouteWithLayout from "./components/Routes/RouteWithLayout";
import UnauthenticatedRoute from "./components/Routes/UnauthenticatedRoute/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/Routes/AuthenticatedRoute/AuthenticatedRoute";

import { Main as MainLayout, Minimal } from "./layouts";

import {
  UserList as UserListView,
  Account as AccountView,
  Settings as SettingsView,
  Login as LoginView,
  SignUp as SignUpView,
  SignUpConfirm as SignUpConfirmView,
  NotFound as NotFoundView,
  Trails as TrailsView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <UnauthenticatedRoute
        path="/"
        exact
        component={LoginView}
        layout={Minimal}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={LoginView}
        layout={Minimal}
      />
      <UnauthenticatedRoute
        path="/signup"
        exact
        component={SignUpView}
        layout={Minimal}
      />
      <UnauthenticatedRoute
        path="/signup-confirm"
        exact
        component={SignUpConfirmView}
        layout={Minimal}
      />
      <AuthenticatedRoute
        path="/"
        exact
        component={TrailsView}
        layout={MainLayout}
      />
      <AuthenticatedRoute
        path="/trails"
        exact
        component={TrailsView}
        layout={MainLayout}
      />
      <AuthenticatedRoute
        path="/users"
        exact
        component={UserListView}
        layout={MainLayout}
      />
      <AuthenticatedRoute
        path="/account"
        exact
        component={AccountView}
        layout={MainLayout}
      />
      <AuthenticatedRoute
        path="/settings"
        exact
        component={SettingsView}
        layout={MainLayout}
      />
      <RouteWithLayout component={NotFoundView} layout={Minimal} />
    </Switch>
  );
};

export default Routes;
