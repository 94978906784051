import { IconLayer } from "deck.gl";

import {ICON__MAPPING} from './iconMapping';

export const renderAlertLayer = (props) => {
  const { data, onHover, selected } = props;

  const alertLayer = [new IconLayer({
        id: "alerts",
        pickable: true,
        data,
        iconAtlas: 'https://s3.us-west-2.amazonaws.com/outway.io/iconAtlas.png',
        iconMapping: ICON__MAPPING,
        getIcon: d => "alert_icon",
        sizeScale: 15,
        getPosition: d => d.coordinates,
        getSize: d => 1.5,
        onHover,
  })]

  return alertLayer;
};

export default renderAlertLayer;
