import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../utility";

const initialState = {
  error: null,
  isLoading: false,
  currentUser: null,
  users: null,
  adding: false,
  selection: null,
};

const getUserStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const getUserSuccess = (state, action) => {
  return updateObject(state, {
    currentUser: action.user,
    error: null,
    isLoading: false,
  });
};

const getUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const getUsersStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const getUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users,
    error: null,
    isLoading: false,
  });
};

const getUsersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const createUserStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const createUserSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users, //TODO: check if this needs to be adjusted to just append to the users state.
    error: null,
    isLoading: false,
  });
};

const createUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const updateUserStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const updateUserSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users, //TODO: check if this needs to be adjusted to just append to the users state.
    error: null,
    isLoading: false,
  });
};

const updateUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const deleteUserStart = (state) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const deleteUserSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users, //TODO: check if this needs to be adjusted to just append to the users state.
    error: null,
    isLoading: false,
  });
};

const deleteUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const clearUser = (state) => {
  return updateObject(state, {
    currentUser: null,
    isLoading: false,
  });
};

// const addUserStart = state => {
//   return updateObject(state, {
//     adding: true,
//     error: null,
//   });
// };

// const addUserSuccess = (state, action) => {
//   return updateObject(state, {
//     // users:action.users,
//     adding: false,
//     error: null,
//   });
// };

// const addUserCancel = (state, action) => {
//   return updateObject(state, {
//     error: null,
//     adding: false,
//   });
// };

// const selectUser = (state, action) => {
//   return updateObject(state, {
//     selection: action.selection,
//   });
// };

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_START:
      return getUserStart(state, { error: null, isLoading: true });
    case actionTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case actionTypes.GET_USER_FAIL:
      return getUserFail(state, action);
    case actionTypes.GET_USERS_START:
      return getUsersStart(state, { error: null, isLoading: true });
    case actionTypes.GET_USERS_SUCCESS:
      return getUsersSuccess(state, action);
    case actionTypes.GET_USERS_FAIL:
      return getUsersFail(state, action);
    case actionTypes.CREATE_USER_START:
      return createUserStart(state, { error: null, isLoading: true });
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state, action);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state, { error: null, isLoading: true });
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state, { error: null, isLoading: true });
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.CLEAR_USER:
      return clearUser(state, { currentUser: null, isLoading: false });
    // case actionTypes.ADD_USER_START:
    //   return addUserStart(state, { error: null, isLoading: true });
    // case actionTypes.ADD_USER_SUCCESS:
    //   return addUserSuccess(state, action);
    // case actionTypes.ADD_USER_CANCEL:
    //   return addUserCancel(state, action);

    // case actionTypes.SELECT_USER:
    //   return selectUser(state, action);
    default:
      return state;
  }
};

export default userReducer;
