import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = (props) => {
  const { className, user, ...rest } = props;

  const classes = useStyles();

  const tempUser = {
    name: "Aaron Kennedy",
    avatar: "/images/avatars/avatar_0.png",
    bio: "CEO",
  };

  return (
    <>
      {user ? (
        <div {...rest} className={clsx(classes.root, className)}>
          <Avatar
            alt="Person"
            className={classes.avatar}
            component={RouterLink}
            src={tempUser.avatar}
            to="/settings"
          />
          <Typography className={classes.name} variant="h4">
            {user.first_name} {user.last_name}
          </Typography>
          <Typography variant="body2">{tempUser.bio}</Typography>
        </div>
      ) : null}
    </>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    user: state.users.currentUser,
    // authenticated: state.auth.authenticated,
    // loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
