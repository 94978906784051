import React from "react";
import { connect } from "react-redux";
import { FormControl, FormControlLabel, Checkbox } from "@material-ui/core";

import * as actions from "../../../../store/actions";

const UserPermissions = ({
  rowData,
  updateTenant,
  currentOrg,
  currentUser,
}) => {
  const handleChange = (e) => {
    let tenant = {
      id: rowData.tenantId,
      [e.target.name]: e.target.checked,
    };
    const orgId = currentOrg.id;
    if (orgId) {
      updateTenant(tenant, orgId);
    }
  };

  return (
    <>
      {/* <div>{JSON.stringify(rowData)}</div> */}
      <FormControl component="fieldset">
        <FormControlLabel
          value="admin"
          control={
            <Checkbox
              name="admin"
              color="primary"
              checked={rowData.isAdmin}
              onChange={handleChange}
              disabled={rowData.userId === currentUser.id}
            />
          }
          label="Admin"
          labelPlacement="start"
        />
      </FormControl>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentOrg: state.orgs.currentOrg,
    currentUser: state.users.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTenant: (tenant, orgId) =>
      dispatch(actions.updateTenant(tenant, orgId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissions);
