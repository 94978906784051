import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import compose from "recompose/compose";

import * as actions from "../../store/actions";
import { aggUserData } from "../../utilities/aggregateUserData";
import { UsersTable } from "./components";

const styles = (theme) => {
  return {
    root: {
      padding: theme.spacing(4),
    },
  };
};

const UserList = (props) => {
  useEffect(() => {
    if (!props.currentOrg && !props.isLoading) {
      props.getOrg(props.currentUser.organizations[0].id);
      props.getUsers();
    }
  });

  const { classes, users, allUsers } = props;
  // TODO: make sure to filter out users already in currentOrg
  const formattedUsers = aggUserData(users);
  const isAdmin = props.currentUser.organizations[0].tenants.admin;
  const userId = props.currentUser.id;

  return (
    <div className={classes.root}>
      <UsersTable
        userId={userId}
        users={formattedUsers}
        allUsers={allUsers}
        isLoading={props.isLoading}
        isAdmin={isAdmin}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentOrg: state.orgs.currentOrg,
    allUsers: state.users.users,
    users: state.orgs.users,
    currentUser: state.users.currentUser,
    isLoading: state.orgs.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrg: (orgId) => dispatch(actions.getOrg(orgId)),
    getUsers: () => dispatch(actions.getUsers()),
  };
};

export default compose(
  withStyles(styles, { name: "UserList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(UserList);
