import React from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiInputBase-root': {
            height: '45px',
            background: '#FFFDF9',
            border: `1px solid #007DAD`,
            boxSizing: 'border-box',
            borderRadius: theme.spacing(0.5),
        },
        '& .Mui-error.Mui-error': {
            border: "1px solid #87373D",
        },
        '& .MuiFormHelperText-root.Mui-error': {
            border: 0,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
        },
        '& .MuiOutlinedInput-input': {
            padding: "13px 14px",
        },
    },
}))

export default function OutwayInput(props) {
    const { name, label, value, onChange, className = null, ...other } = props;
    const classes = useStyles();

    return (
        <TextField 
        variant="outlined"
        name={name}
        value={value}
        onChange={onChange}
        className={className ? `${classes.root} ${className}` : classes.root}
        {...other}
        />
    )
}
