import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
// import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";
// import uuid from 'uuid';
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@material-ui/core";

// import { getInitials } from 'helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 0,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    alignItems: "center",
    verticalAlign: "middle",
    height: "20px",
    // width:'4%'
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
    height: "40px",
  },
}));

const AssetTable = (props) => {
  const { className, Asset, ...rest } = props;

  const classes = useStyles();

  const [selectedAsset, setSelectedAsset] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    const { Asset } = props;

    let selectedAsset;

    if (event.target.checked) {
      selectedAsset = Asset.map((asset) => asset.id);
    } else {
      selectedAsset = [];
    }

    setSelectedAsset(selectedAsset);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedAsset.indexOf(id);
    let newSelectedAsset = [];

    if (selectedIndex === -1) {
      newSelectedAsset = newSelectedAsset.concat(selectedAsset, id);
    } else if (selectedIndex === 0) {
      newSelectedAsset = newSelectedAsset.concat(selectedAsset.slice(1));
    } else if (selectedIndex === selectedAsset.length - 1) {
      newSelectedAsset = newSelectedAsset.concat(selectedAsset.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedAsset = newSelectedAsset.concat(
        selectedAsset.slice(0, selectedIndex),
        selectedAsset.slice(selectedIndex + 1)
      );
    }

    setSelectedAsset(newSelectedAsset);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    console.log(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAsset.length === Asset.length}
                      color="primary"
                      indeterminate={
                        selectedAsset.length > 0 &&
                        selectedAsset.length < Asset.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell padding="checkbox">Difficulty</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Maintance</TableCell>
                  <TableCell>Registration date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Asset.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((asset) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={asset.id}
                    selected={selectedAsset.indexOf(asset.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAsset.indexOf(asset.id) !== -1}
                        color="primary"
                        onChange={(event) => handleSelectOne(event, asset.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{asset.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>Downhill Ski Run</TableCell>
                    <TableCell>
                      <div>
                        <img
                          alt=""
                          className={classes.avatar}
                          src={asset.iconUrl}
                          style={{ height: "20px" }}
                        />
                      </div>
                    </TableCell>
                    <TableCell>No Maintence Record</TableCell>
                    <TableCell>
                      No Maintence Record
                      {/* {moment(asset.maintenance).format('DD/MM/YYYY')} */}
                    </TableCell>
                    <TableCell>
                      No Maintence Record
                      {/* {moment(asset.createdAt).format('DD/MM/YYYY')} */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={Asset.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

AssetTable.propTypes = {
  className: PropTypes.string,
  Asset: PropTypes.array.isRequired,
};

export default AssetTable;
