import React, { useState } from "react";
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { pointLocationTypes, pointLocationTypeAttributes } from "../../../utilities/mapConstants";
import { snakeToWords } from "../../../utilities/textManipulation";
import * as actions from "../../../store/actions/index";
import {
  OutwayInput, 
  OutwayDropdown, 
  OutwayTextarea
} from "../../ui";
import {
  Button,
  Typography,
  Card,
  Link,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox"

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 31,
    bottom: 53,
    marginLeft: theme.spacing(3),
    backgroundColor: "#FFFDF9",
    width: "30vw",
  },
  content: {
    height: "100%",
  },
  dialogForm: {
    margin: theme.spacing(1),
    height: "100%",
    '& .MuiFormControl-root': {
      minWidth: "60px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  locationDiv: {
    display: "flex",
    flexDirection: "column",
  },
  coordinate: {
    width: "84px",
    marginRight: "12px",
  },
  coordinateDiv: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "12px",
    '& button': {
      marginLeft: "auto",
    },
  },
  formComponent: {
    width: '100%',
    marginBottom: "12px",
  },
  formTitle: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "18px",
    color: theme.palette.black,
    marginBottom: "17px",
  },
  inputTitle: {
    marginBottom: "12px",
  },
  underlinedText: {
    textDecoration: "underline",
  },
  createButton: {
    color: "#FFFFFF",
      backgroundColor: "#007DAD",
      marginBottom: theme.spacing(1),
      width: "40%",
      height: "45px",
      maxWidth: "180px",
      textTransform: 'none',
  },
  bottomDiv: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "space-between",
  },
  attributeButton: {
    backgroundColor: "#ECEAE8",
    color: "#6C6C6C",
    border: 0,
    fontSize: "14px",
    fontWeight: "600",
    marginRight: "12px",
  },
}));

const AddLocation = (props) => {
  const { onClose, type, locationCoordinates } = props;
  const classes = useStyles();

  const [locationData, setlocationData] = useState({
    name: "",
    type,
    geom: { type: "Point", coordinates: locationCoordinates },
    attributes: {},
  });

  const getTypeList = (type) => {
    let returnElements = [];
    switch (type) {
      case 'point':
        pointLocationTypes.map((type) => 
          returnElements.push(<MenuItem value={type}>{snakeToWords(type)}</MenuItem>)
        )
        break;
      case 'alert':
        returnElements.push(<MenuItem value={type}>{snakeToWords(type)}</MenuItem>);
        break;
      default:
        break;
    }
    return returnElements;
  };

  const handleAddLocationForm = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const oldCoordinates = locationData.geom.coordinates;
    let newCoordinates = oldCoordinates;
    if (name === 'lat') {
      newCoordinates[0] = value;
      setlocationData({
        ...locationData, geom: {
        ...locationData.geom,
        coordinates: newCoordinates
      }});
    }
    else if (name === 'long') {
      newCoordinates[1] = value;
      setlocationData({
        ...locationData, geom: {
        ...locationData.geom,
        coordinates: newCoordinates
      }});
    }
    else {
      setlocationData({
        ...locationData,
        [name]: value
      });
    }
  };

  const handleAddLocationAttributes = (event) => {
    setlocationData({
      ...locationData,
      attributes: {
        ...locationData.attributes,
        [event.target.name]: event.target.value
      }
    });
  };

  const getAddLocationAttributeElement = (attribute) => {
    let returnElement;
    const elementType = attribute.type;
    switch (elementType) {
      case 'boolean':
        returnElement = <FormControlLabel
          className={classes.formComponent}
          control={<Checkbox checked={locationData.attributes[attribute.key]} onChange={handleAddLocationAttributes} name={attribute.key} checkedIcon={<CheckBoxIcon className={classes.checkbox}/>}/>}
          label={attribute.name}/>
        break;
      case 'string':
        returnElement = <div>
          <Typography className={classes.inputTitle}>
            Description
          </Typography>
          <OutwayTextarea
          minRows={10}
          placeholder={attribute.name}
          label={attribute.name}
          name={attribute.key}
          data={attribute}
          className={classes.formComponent}
          value={locationData.attributes[attribute.key] ? locationData.attributes[attribute.key] : null}
          onChange={handleAddLocationAttributes}/>
          <OutwayInput 
          label="Tags"
          name="tags"
          placeholder="Tags"
          className={classes.formComponent}/>
          <div>
            <Button
            variant="outlined"
            className={classes.attributeButton}
            >Attribute No. 1</Button>
            <Button
            variant="outlined"
            className={classes.attributeButton}
            >Attribute No. 2</Button>
          </div>
        </div>
        break;
      case 'select':
        returnElement = 
        <FormControl className={classes.formComponent}>
          <InputLabel id="add-location-attribute-type">{attribute.name}</InputLabel>
          <Select
            labelId="add-location-attribute-type"
            id={"location-attribute-"  + attribute.key}
            name={attribute.key}
            value={locationData.attributes[attribute.key]}
            onChange={handleAddLocationAttributes}
          >
            {attribute.values.map((type) => 
              <MenuItem value={type}>{snakeToWords(type)}</MenuItem>
            )}
          </Select>
        </FormControl>
        break;
      default:
        break;
    }
    return returnElement;
  };

  const confirmAddLocation = () => {
    const type = locationData.type;
    locationData[type + '_attribute'] = locationData.attributes;
    delete locationData.attributes;
    if (locationData.type === 'alert') {
      locationData.alert_attribute.user_id = props.currentUser.id;
      props.createAlert(locationData);
    }
    else {
      console.log(locationData)
      props.createPoint(locationData);
    }
    setlocationData({});
    onClose();
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <form className={classes.dialogForm}>
          <Typography
          className={classes.formTitle}
          >
            {locationData.name ? locationData.name : "Add a Point Location"}
          </Typography>
          <div>
            <Typography className={classes.inputTitle}>
              Location Type
            </Typography>
            <OutwayDropdown
            labelId="add-location-type"
            id="location-type-select"
            name="type"
            value={locationData["type"]}
            onChange={handleAddLocationForm}
            >
              {getTypeList(type)}
            </OutwayDropdown>
          </div>
          <div>
            <Typography className={classes.inputTitle}>
              Location Name
            </Typography>
            <OutwayInput
              label="Name"
              name="name"
              placeholder="Trailhead Restrooms"
              value={locationData["name"]}
              onChange={handleAddLocationForm}
              className={classes.formComponent}/>
          </div>
          <div className={classes.locationDiv}>
            <Typography className={classes.inputTitle}>
              Location
            </Typography>
            <div className={classes.coordinateDiv}>
              <OutwayInput
                label="Latitude"
                name="lat"
                type="number"
                placeholder="Lat"
                disabled
                value={locationData.geom.coordinates[0]}
                onChange={handleAddLocationForm}
                className={classes.coordinate}/>
              <OutwayInput
                label="Longitude"
                name="long"
                type="number"
                placeholder="Long"
                disabled
                value={locationData.geom.coordinates[1]}
                onChange={handleAddLocationForm}
                className={classes.coordinate}/>
                <Link component="button" className={classes.underlinedText}>
                  Drop on Map
                </Link>
            </div>
          </div>
          <Collapse in={Boolean(locationData["type"])} unmountOnExit>
            <FormControl className={classes.formComponent}>
              <FormGroup>
                {pointLocationTypeAttributes[locationData["type"]] && pointLocationTypeAttributes[locationData["type"]].length
                  ? pointLocationTypeAttributes[locationData["type"]].map((attribute, index) =>
                  getAddLocationAttributeElement(attribute)
                ) : null}
              </FormGroup>
            </FormControl>
          </Collapse>
          <div className={classes.bottomDiv}>
            {/* TODO: need to add save as draft functionality */}
            <Link 
            className={classes.underlinedText} 
            component="button" 
            onClick={onClose}
            // >Save as Draft</Link>
            >Cancel</Link>
            <Button 
            onClick={confirmAddLocation}
            className={classes.createButton}
            size="large"
            type="submit"
            variant="contained"
            >Create</Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.users.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPoint: (object) => dispatch(actions.createPoint(object)),
    createAlert: (object) => dispatch(actions.createAlert(object)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);
