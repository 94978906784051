import React from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

import * as actions from "../../../store/actions";
// import { AssetToolbar, AssetTable, AssetMap } from '../components';
import Map from "../../../components/MapTools/Map";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  map: {
    height: "100%",
    width: "100%",
    zIndex: 50,
    position: "relative",
  },
  mapActionBar: {
    // display: 'inline-block',
    width: "100%",
    position: "absolute",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginRight: theme.spacing(4),
    zIndex: 100,
  },
}));

const Trails = () => {
  const classes = useStyles();
  return (
    <div className={classes.map}>
      <Map />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.assets.loading,
    error: state.assets.error,
    trails: state.assets.trails,
    showMap: state.map.showMap,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAssets: () => dispatch(actions.getAssets()),
    switchShowMap: () => dispatch(actions.switchShowMap()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trails);
