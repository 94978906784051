import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import { Notifications, Password } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Settings = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12} sm={10} md={8} lg={8}>
          <Notifications />
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={8}>
          <Password />
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
