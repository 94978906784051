import { API } from "aws-amplify";

import * as actionTypes from "./actionTypes";
import config from "../../config";
import { pointLocationTypes } from "../../utilities/mapConstants";

export const getPointsStart = () => {
  return {
    type: actionTypes.GET_POINTS_START,
  };
};

export const getPointsSuccess = (points) => {
  return {
    type: actionTypes.GET_POINTS_SUCCESS,
    points: points,
  };
};

export const getPointsFail = (err) => {
  return {
    type: actionTypes.GET_POINTS_FAIL,
    error: err,
  };
};

export const getPoints = (types = pointLocationTypes) => {
  return (dispatch) => {
    dispatch(getPointsStart());
    const myInit = {
      // OPTIONAL
      headers: {
        "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c",
      },
      body: {
        type: types
      },
      "content-type": "application/json"
    };
    API.post("outway", "/locations", myInit)
      .then((res) => {
        console.log(res);
        dispatch(getPointsSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const createPointStart = () => {
  return {
    type: actionTypes.CREATE_POINT_START,
  };
};

export const createPointSuccess = (assets) => {
  return {
    type: actionTypes.CREATE_POINT_SUCCESS,
    assets: assets,
  };
};

export const createPointFail = (err) => {
  return {
    type: actionTypes.CREATE_POINT_FAIL,
    error: err,
  };
};

export const createPoint = (object) => {
  console.log(object)
  return (dispatch) => {
    console.log(config);
    dispatch(createPointStart());
    const myInit = {
      // OPTIONAL
      headers: {
        "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c",
      },
      body: object, // OPTIONAL
    };
    API.post("outway", "/location", myInit)
      .then((res) => {
        console.log(res);
        dispatch(createPointSuccess(res));
        dispatch(getPoints()); // TODO: optimize
      })
      .catch((err) => console.log(err));
  };
};

export const updatePointStart = () => {
  return {
    type: actionTypes.UPDATE_POINT_START,
  };
};

export const updatePointSuccess = (assets) => {
  return {
    type: actionTypes.UPDATE_POINT_SUCCESS,
    assets: assets,
  };
};

export const updatePointFail = (err) => {
  return {
    type: actionTypes.UPDATE_POINT_FAIL,
    error: err,
  };
};

export const updatePoint = (data) => {
  data[data.type + '_attribute'] = data['attributes'];
  return (dispatch) => {
    // console.log(data);
    dispatch(updatePointStart());
    const myInit = {
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
      body: data,
    };
    API.put("outway", "/location/" + data.id, myInit)
      .then((res) => {
        console.log(res);
        dispatch(updatePointSuccess(res));
        dispatch(getPoints()); // TODO: optimize
      })
      .catch((err) => console.log(err));
  };
};

export const deletePointStart = () => {
  return {
    type: actionTypes.DELETE_POINT_START,
  };
};

export const deletePointSuccess = (assets) => {
  return {
    type: actionTypes.DELETE_POINT_SUCCESS,
    assets: assets,
  };
};

export const deletePointFail = (err) => {
  return {
    type: actionTypes.DELETE_POINT_FAIL,
    error: err,
  };
};

export const deletePoint = (object) => {
  return (dispatch) => {
    console.log(config);
    dispatch(deletePointStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.del('outway', '/location/' + object.id, myInit)
      .then(res => {
        console.log(res);
        dispatch(deletePointSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};
