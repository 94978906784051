export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const AUTH_LOGOUT_START = "AUTH_LOGOUT_START";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const AUTH_USER = "auth_user";
export const AUTH_MFA = "auth_mfa";
export const AUTH_NEW_PASSWORD_REQUIRED = "auth_new_password_required";
export const UNAUTH_USER = "unauth_user";

export const SIGNUP_USER = "register_user";
export const SIGNUP_USER_CONFIRM = "register_user_confirm";
export const SIGNUP_MFA = "register_mfa";
export const SIGNUP_USER_ERROR = "register_user_error";

export const FORGOT_PASSWORD = "forgot_password";
export const FORGOT_PASSWORD_CONFIRM = "forgot_password_confirm";

export const AUTH_ERROR = "auth_error";
export const AUTH_ERROR_CLEAR = "auth_error_clear";

export const GET_ASSETS_START = "GET_ASSETS_START";
export const GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS";
export const GET_ASSETS_FAIL = "GET_ASSETS_FAIL";

export const GET_TRAILS_START = "GET_TRAILS_START";
export const GET_TRAILS_SUCCESS = "GET_TRAILS_SUCCESS";
export const GET_TRAILS_FAIL = "GET_TRAILS_FAIL";

export const CREATE_TRAIL_START = "CREATE_TRAIL_START";
export const CREATE_TRAIL_SUCCESS = "CREATE_TRAIL_SUCCESS";
export const CREATE_TRAIL_FAIL = "CREATE_TRAIL_FAIL";

export const UPDATE_TRAIL_START = "UPDATE_TRAIL_START";
export const UPDATE_TRAIL_SUCCESS = "UPDATE_TRAIL_SUCCESS";
export const UPDATE_TRAIL_FAIL = "UPDATE_TRAIL_FAIL";

export const DELETE_TRAIL_START = "DELETE_TRAIL_START";
export const DELETE_TRAIL_SUCCESS = "DELETE_TRAIL_SUCCESS";
export const DELETE_TRAIL_FAIL = "DELETE_TRAIL_FAIL";

export const ADD_ASSET_START = "ADD_ASSET_START";
export const ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS";
export const ADD_ASSET_CANCEL = "ADD_ASSET_CANCEL";

export const SELECT_ASSET = "SELECT_ASSET";

export const SWITCH_SHOWMAP = "SWITCH_SHOWMAP";
export const GET_VIEWPORT_START = "GET_VIEWPORT_START";
export const GET_VIEWPORT_SUCCESS = "GET_VIEWPORT_START";
export const GET_VIEWPORT_FAIL = "GET_VIEWPORT_FAIL";

export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const CLEAR_USER = "CLEAR_USER";

export const GET_ORG_START = "GET_ORG_START";
export const GET_ORG_SUCCESS = "GET_ORG_SUCCESS";
export const GET_ORG_FAIL = "GET_ORG_FAIL";

export const GET_ORGS_START = "GET_ORGS_START";
export const GET_ORGS_SUCCESS = "GET_ORGS_SUCCESS";
export const GET_ORGS_FAIL = "GET_ORGS_FAIL";

export const CREATE_ORG_START = "CREATE_ORG_START";
export const CREATE_ORG_SUCCESS = "CREATE_ORG_SUCCESS";
export const CREATE_ORG_FAIL = "CREATE_ORG_FAIL";

export const UPDATE_ORG_START = "UPDATE_ORG_START";
export const UPDATE_ORG_SUCCESS = "UPDATE_ORG_SUCCESS";
export const UPDATE_ORG_FAIL = "UPDATE_ORG_FAIL";

export const DELETE_ORG_START = "DELETE_ORG_START";
export const DELETE_ORG_SUCCESS = "DELETE_ORG_SUCCESS";
export const DELETE_ORG_FAIL = "DELETE_ORG_FAIL";

export const CLEAR_ORG = "CLEAR_ORG";

export const UPDATE_TENANT_START = "UPDATE_TENANT_START";
export const UPDATE_TENANT_SUCCESS = "UPDATE_TENANT_SUCCESS";
export const UPDATE_TENANT_FAIL = "UPDATE_TENANT_FAIL";

export const ADD_TENANT_START = 'ADD_TENANT_START';
export const ADD_TENANT_SUCCESS = 'ADD_TENANT_SUCCESS';
export const ADD_TENANT_FAIL = 'ADD_TENANT_FAIL';

export const DELETE_TENANT_START = 'DELETE_TENANT_START';
export const DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS';
export const DELETE_TENANT_FAIL = 'DELETE_TENANT_FAIL';

export const GET_ACTIVITIES_START = "GET_ACTIVITIES_START";
export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS";
export const GET_ACTIVITIES_FAIL = "GET_ACTIVITIES_FAIL";

export const CREATE_ACTIVITY_START = "CREATE_ACTIVITY_START";
export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAIL = "CREATE_ACTIVITY_FAIL";

export const UPDATE_ACTIVITY_START = "UPDATE_ACTIVITY_START";
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAIL = "UPDATE_ACTIVITY_FAIL";

export const DELETE_ACTIVITY_START = "DELETE_ACTIVITY_START";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL";

export const GET_REPORTS_START = "GET_REPORTS_START";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL";

export const CREATE_REPORT_START = "CREATE_REPORT_START";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAIL = "CREATE_REPORT_FAIL";

export const UPDATE_REPORT_START = "UPDATE_REPORT_START";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAIL = "UPDATE_REPORT_FAIL";

export const DELETE_REPORT_START = "DELETE_REPORT_START";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAIL = "DELETE_REPORT_FAIL";

export const GET_POINTS_START = "GET_POINTS_START";
export const GET_POINTS_SUCCESS = "GET_POINTS_SUCCESS";
export const GET_POINTS_FAIL = "GET_POINTS_FAIL";

export const CREATE_POINT_START = "CREATE_POINT_START";
export const CREATE_POINT_SUCCESS = "CREATE_POINT_SUCCESS";
export const CREATE_POINT_FAIL = "CREATE_POINT_FAIL";

export const UPDATE_POINT_START = "UPDATE_POINT_START";
export const UPDATE_POINT_SUCCESS = "UPDATE_POINT_SUCCESS";
export const UPDATE_POINT_FAIL = "UPDATE_POINT_FAIL";

export const DELETE_POINT_START = "DELETE_POINT_START";
export const DELETE_POINT_SUCCESS = "DELETE_POINT_SUCCESS";
export const DELETE_POINT_FAIL = "DELETE_POINT_FAIL";

export const GET_ALERTS_START = "GET_ALERTS_START";
export const GET_ALERTS_SUCCESS = "GET_ALERTS_SUCCESS";
export const GET_ALERTS_FAIL = "GET_ALERTS_FAIL";

export const CREATE_ALERT_START = "CREATE_ALERT_START";
export const CREATE_ALERT_SUCCESS = "CREATE_ALERT_SUCCESS";
export const CREATE_ALERT_FAIL = "CREATE_ALERT_FAIL";

export const UPDATE_ALERT_START = "UPDATE_ALERT_START";
export const UPDATE_ALERT_SUCCESS = "UPDATE_ALERT_SUCCESS";
export const UPDATE_ALERT_FAIL = "UPDATE_ALERT_FAIL";

export const DELETE_ALERT_START = "DELETE_ALERT_START";
export const DELETE_ALERT_SUCCESS = "DELETE_ALERT_SUCCESS";
export const DELETE_ALERT_FAIL = "DELETE_ALERT_FAIL";
