import React, { useState } from "react";
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import * as actions from "../../../store/actions/index";
import { findDistance } from "../../../utilities/utilities";
import { mapUsageIcons } from "../../../utilities/UsageIcons";
import { trailSurfaceTypes, locationUsages, hiddenLocationAttributes } from "../../../utilities/mapConstants";
import { snakeToWords } from "../../../utilities/textManipulation";

import { red, green, yellow } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
  Chip,
  CardActions,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormLabel
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 145,
    zIndex: 110,
    bottom: 0,
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
    padding: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  allowed: {
    backgroundColor: green[500],
  },
  prohibited: {
    backgroundColor: red[500],
  },
  other: {
    backgroundColor: yellow[500],
  },
}));

const SelectionPanel = (props) => {
  const { key, locationObject, toggleDetailCard, setDetailCardData } = props;
  const classes = useStyles();

  // TODO: move to mapConstants
  const usageColor = (usageType) => {
    let color = null;
    switch (usageType) {
      case "allowed":
        color = classes.allowed;
        break;
      case "prohibited":
        color = classes.prohibited;
        break;
      case "permit":
        color = classes.other;
        break;
      case "license":
        color = classes.other;
        break;
      case "leashed":
        color = classes.other;
        break;
      case "reservation":
        color = classes.other;
        break;
      case "accessible":
        color = classes.allowed;
        break;
      case "inaccessible":
        color = classes.prohibited;
        break;
      default:
        break;
    }
    return color;
  };

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenDetailCard = () => {
    toggleDetailCard();
    setDetailCardData(locationObject);
  }

  return (
    <Card className={classes.root} key={key}>
      <CardHeader
        className={classes.header}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar} src={locationObject.icon}>
            P
          </Avatar>
        }
        subheader={
          locationObject.path.length > 2 ? "Path Distance: " + findDistance(locationObject.path).toFixed(2) : null
          // TODO: clean this up. or render reports in different card
        }
        title={locationObject.name}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {locationObject.note ? (
            <Typography variant='body2'>
              {String(locationObject.note)}
            </Typography>
          ) : null}
          {locationObject.status ? (
            <Typography variant='body2'>
              {"Status: " + String(locationObject.status)}
            </Typography>
          ) : null}
          {locationObject.attributes ? (
            Object.keys(locationObject.attributes).map((keyName, index) =>
              !hiddenLocationAttributes.includes(keyName) ? (
                <Typography variant='body2'>
                  {snakeToWords(keyName)}: {String(locationObject.attributes[keyName])}
                </Typography>
              ) : null
            )
          ) : null}
          {locationObject.usages
            ? Object.keys(locationObject.usages).map((keyName, index) =>
                locationObject.usages[keyName] &&
                keyName !== "id" &&
                keyName !== "location_id" ? (
                  <Chip
                    // key={'usage_' + { index }}
                    className={classes.chip}
                    variant="outlined"
                    avatar={
                      <Avatar
                        className={usageColor(locationObject.usages[keyName])}
                      >
                        {mapUsageIcons(keyName)}
                      </Avatar>
                    }
                    label={locationObject.usages[keyName]}
                  />
                ) : null
              )
            : null}
        </CardContent>

        <CardActions disableSpacing>
          <IconButton onClick={handleOpenDetailCard} disabled={!locationObject.type} aria-label="open-detail-card">
            <OpenInNewIcon/>
          </IconButton>
        </CardActions>
      </Collapse>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    selection: state.assets.selection,
    trailsArray: state.trails.trails,
    userActivitiesArray: state.userActivities.userActivities,
    reportsArray: state.reports.reports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateTrail: (trail) => dispatch(actions.updateTrail(trail)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionPanel);
