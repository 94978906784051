import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import validate from "validate.js";
import { OutwayInput } from "../../components/ui";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as actions from "../../store/actions/index";
import { useStyles } from "../Login/SignInStyle";

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  policy: {
    presence: { allowEmpty: false, message: "is required" },
    checked: true,
  },
};

const SignUp = (props) => {
  const { history, signup } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSignUp = (event) => {
    console.log(event);
    console.log(formState);
    event.preventDefault();
    signup(formState.values);
    history.push("/signup-confirm");
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const authError = () => {
    let error = null;

    if (props.error) {
      error = (
        <Typography className={classes.errorTitle} variant="body1">
          The email and / or password is incorrect
        </Typography>
      );
    }
    return error;
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item xs={0} sm={4} md={6} lg={8}>
          <div className={classes.quote}>
            {/* TODO: rename class names because there is no more quote */}
          </div>
        </Grid>
        <Grid className={classes.content} item xs={12} sm={8} md={6} lg={4}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignUp}>
                <Grid container className={classes.formContent}>
                  <Grid item xs={12} className={classes.logoContent}>
                    <img
                    alt="Logo1"
                    height="50px"
                    width="50px"
                    src="/images/logos/outway_logo_main_black.png"
                    /> 
                    <img 
                    className={classes.logoName}
                    alt="Logo2"
                    height="25px"
                    width="100px"
                    src="/images/logos/OUTWAY.png"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.formBody}>
                    <Typography className={classes.title}>
                      Land Manager Access
                      {authError()}
                    </Typography>
                    <div className={classes.inputDiv}>
                      <Typography className={classes.inputTitle}>
                        Account Email
                      </Typography>
                      <OutwayInput
                        className={classes.textField}
                        error={hasError("email")}
                        fullWidth
                        helperText={
                          hasError("email") ? formState.errors.email[0] : null
                        }
                        label="Email address"
                        placeholder="landmanager@organization.gov"
                        name="email"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.email || ""}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.inputDiv}>
                      <Typography className={classes.inputTitle}>
                        First Name
                      </Typography>
                      <OutwayInput
                        className={classes.textField}
                        error={hasError("firstName")}
                        fullWidth
                        helperText={
                          hasError("firstName") ? formState.errors.firstName[0] : null
                        }
                        label="First name"
                        placeholder="Randi"
                        name="first_name"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.first_name || ""}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.inputDiv}>
                      <Typography className={classes.inputTitle}>
                        Last Name
                      </Typography>
                      <OutwayInput
                        className={classes.textField}
                        error={hasError("lastName")}
                        fullWidth
                        helperText={
                          hasError("lastName") ? formState.errors.lastName[0] : null
                        }
                        label="Last name"
                        placeholder="Ranger"
                        name="last_name"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.last_name || ""}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.inputDiv}>
                      <Typography className={classes.inputTitle}>
                        Password
                      </Typography>
                      <OutwayInput
                        className={classes.textField}
                        error={hasError("password")}
                        fullWidth
                        helperText={
                          hasError("password") ? formState.errors.password[0] : null
                        }
                        label="Password"
                        placeholder="*************"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={formState.values.password || ""}
                        variant="outlined"
                      />
                    </div>
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      classes={{ disabled: classes.disabledButton }}
                    >
                      Sign Up 
                    </Button>

                  </Grid>
                  <Grid item xs={12} className={classes.signupLinkContent}
                  >
                    <Typography color="textSecondary" variant="body1" style={{marginBottom: "15px"}}>
                      Already have an account?{" "}
                      <Link component={RouterLink} to="/login" variant="h6" style={{fontWeight: "600"}}>
                        Login
                      </Link>
                    </Typography>
                    <Typography color="textPrimary" variant="body1" 
                    style={{
                      alignSelf: "flex-end"
                    }}
                    >
                      Need Help?{" "}
                      {/* TODO: add proper routing to the contact page */}
                      <Link component={RouterLink} to="/signup" variant="body1">
                        Contact Us
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    signup: (data) => dispatch(actions.signup(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));

