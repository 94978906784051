import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { OutwayInput } from "../../components/ui";
import {
  Grid,
  Button,
  Link,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useStyles } from "./SignInStyle";
import * as actions from "../../store/actions";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const Login = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    console.log(props);
    props.onLogin(
      formState.values.email,
      formState.values.password,
      props.history
    );
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const authError = () => {
    let error = null;

    if (props.error) {
      error = (
        <Typography className={classes.errorTitle} variant="body1">
          The email and / or password is incorrect
        </Typography>
      );
    }
    return error;
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item xs={0} sm={4} md={6} lg={8}>
          <div className={classes.quote}>
            {/* TODO: rename class names because there is no more quote */}
          </div>
        </Grid>
        <Grid className={classes.content} item xs={12} sm={8} md={6} lg={4}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleLogin}>
                <Grid container className={classes.formContent}>
                  <Grid item xs={12} className={classes.logoContent}>
                    <img
                    alt="Logo1"
                    height="50px"
                    width="50px"
                    src="/images/logos/outway_logo_main_black.png"
                    /> 
                    <img 
                    className={classes.logoName}
                    alt="Logo2"
                    height="25px"
                    width="100px"
                    src="/images/logos/OUTWAY.png"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.formBody}>
                    <Typography className={classes.title}>
                      Land Manager Access
                      {authError()}
                    </Typography>
                    <div className={classes.inputDiv}>
                      <Typography className={classes.inputTitle}>
                        Account Email
                      </Typography>
                      <OutwayInput 
                        className={classes.textField}
                        error={hasError("email")}
                        fullWidth
                        helperText={
                          hasError("email") ? formState.errors.email[0] : null
                        }
                        label="Email address"
                        name="email"
                        onChange={handleChange}
                        type="text"
                        placeholder="landmanager@organization.gov"
                        value={formState.values.email || ""}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.inputDiv}>
                      <Typography className={classes.inputTitle}>
                        Password
                      </Typography>
                      <OutwayInput 
                        className={classes.textField}
                        error={hasError("password")}
                        fullWidth
                        helperText={
                          hasError("password") ? formState.errors.password[0] : null
                        }
                        label="Password"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        placeholder="*************"
                        value={formState.values.password || ""}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.signInDiv}>
                      <Button
                        className={classes.signInButton}
                        disabled={!formState.isValid}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        classes={{ disabled: classes.disabledButton }}
                      >
                        Login
                      </Button>
                      {/* TODO: need to add functionality here */}
                      {/* <Link component={RouterLink} to="/">
                        Forgot Password?
                      </Link> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} className={classes.loginLinkContent}>
                    <Typography color="textPrimary" variant="body1">
                      Don't have an account?{" "}
                      <Link component={RouterLink} to="/signup"
                      style={{
                        fontWeight: "600"
                      }}
                      >
                        Sign up
                      </Link>
                    </Typography>
                    <Typography color="textPrimary" variant="body1" 
                    style={{
                      alignSelf: "flex-end"
                    }}
                    >
                      Need Help?{" "}
                      <Link component={RouterLink} to="/signup" variant="body1">
                        Contact Us
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    errorMessage: state.auth.error,
    mfaRequired: state.auth.mfa,
    changePassword: state.auth.changePassword,
    cognitoUser: state.auth.cognitoUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (email, password, history) =>
      dispatch(actions.login(email, password, history)),
    onConfirmLogin: ({ cognitoUser, code }, history) =>
      dispatch(actions.confirmLogin({ cognitoUser, code }, history)),
    onSetNewPassword: ({ cognitoUser, newPassword }, history) =>
      dispatch(actions.setNewPassword({ cognitoUser, newPassword }, history)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));

