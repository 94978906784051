import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Badge, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";

import Logout from "../Logout";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    minHeight: "0",
    height: "6.5vh",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, logOutHandler, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style={{ borderBottom: "0.2em solid #c25e00" }}
    >
      <Toolbar className={clsx(classes.root, className)}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>

        <div className={classes.flexGrow} />
        <Hidden smDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Logout clicked={logOutHandler} />
        </Hidden>
        <RouterLink to="/">
          <img
            alt="Logo"
            height="35vh"
            style={{ verticalAlign: "middle", marginLeft: "15px" }}
            src="/images/logos/OutwayLogo.png"
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  logOutHandler: PropTypes.func,
};

export default Topbar;
