const dev = {
  STRIPE_KEY: "pk_test_XH8RrpzZulHSxBYevzDm1XCU00uEGUezmd",
  s3: {
    REGION: "us-west-2",
    BUCKET: "serverless-app-2-api-dev-attachmentsbucket-ftpvnk9dicme",
  },
  apiGateway: {
    REGION: "us-west-2",
    NAME: "outway",
    URL: "https://api.outway.io/dev",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_uj4Hy0szk",
    APP_CLIENT_ID: "7jbhg8ek3e67u20itfld8hsan2",
    IDENTITY_POOL_ID: "us-west-2:c4d6fc0a-c758-4bb5-bccf-0365d58a1df2",
  },
};

const prod = {
  STRIPE_KEY: "pk_test_XH8RrpzZulHSxBYevzDm1XCU00uEGUezmd",
  s3: {
    REGION: "YOUR_PROD_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "serverless-app-2-api-prod-attachmentsbucket-ix0q1p11zdf4",
  },
  apiGateway: {
    REGION: "us-west-2",
    NAME: "outway",
    URL: "https://api.outway.io/prod",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_uj4Hy0szk",
    APP_CLIENT_ID: "7jbhg8ek3e67u20itfld8hsan2",
    IDENTITY_POOL_ID: "us-west-2:c4d6fc0a-c758-4bb5-bccf-0365d58a1df2",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
