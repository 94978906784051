import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import validate from "validate.js";
import { useStyles } from "../Login/SignInStyle";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as actions from "../../store/actions";

const schema = {
  code: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 10,
    },
  },
};

const SignUpConfirm = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleVerification = (event) => {
    event.preventDefault();
    console.log(props);
    console.log(formState.values);
    props.confirmRegistration(
      props.email,
      props.password,
      props.userId,
      formState.values.code,
      props.history
    );
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const authError = () => {
    let error = null;

    if (props.error) {
      error = (
        <Typography className={classes.errorTitle} variant="h2">
          Incorrect Verification Code
        </Typography>
      );
    }
    // console.log(error, props)
    return error;
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
            <Typography className={classes.quoteText} variant="h1">
                In every walk with nature one receives far more than he seeks.
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  John Muir
                </Typography>
                {/* <Typography className={classes.bio} variant="body2">
                  CEO Outway
                </Typography> */}
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleVerification}>
                <Typography className={classes.title} variant="h2">
                  Enter Verification Code
                </Typography>
                {authError()}
                <TextField
                  className={classes.textField}
                  error={hasError("code")}
                  fullWidth
                  helperText={
                    hasError("code") ? formState.errors.code[0] : null
                  }
                  name="code"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.code || ""}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
                <Typography color="textPrimary" variant="body1">
                  Don't have an account?{" "}
                  <Link component={RouterLink} to="/signup" variant="h6">
                    Sign up
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUpConfirm.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    email: state.auth.email,
    password: state.auth.password,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmRegistration: (email, password, username, code, history) =>
      dispatch(
        actions.confirmRegistration(email, password, username, code, history)
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignUpConfirm)
);
