export const aggUserData = (users) => {
  const aggUsers = [];
  users.forEach((user) => {
    aggUsers.push({
      userId: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      tenantId: user.tenants.id,
      isAdmin: user.tenants.admin,
      orgPermission: user.tenants.organization_permissions,
      trailPermission: user.tenants.trails_permissions,
      tenantPermission: user.tenants.tenants_permissions,
    });
  });
  return aggUsers;
};
