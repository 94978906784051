import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../utility";

const initialState = {
  error: null,
  loading: false,
  reports: null,
  adding: false,
  selection: null,
};

const getReportsStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getReportsSuccess = (state, action) => {
  return updateObject(state, {
    reports: action.reports,
    error: null,
    loading: false,
  });
};

const getReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const createReportStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const createReportSuccess = (state, action) => {
  return updateObject(state, {
    reports: action.reports, //TODO: check if this needs to be adjusted to just append to the reports state.
    error: null,
    loading: false,
  });
};

const createReportFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const updateReportStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const updateReportSuccess = (state, action) => {
  return updateObject(state, {
    reports: action.reports, //TODO: check if this needs to be adjusted to just append to the reports state.
    error: null,
    loading: false,
  });
};

const updateReportFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const deleteReportStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const deleteReportSuccess = (state, action) => {
  return updateObject(state, {
    reports: action.reports, //TODO: check if this needs to be adjusted to just append to the reports state.
    error: null,
    loading: false,
  });
};

const deleteReportFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

// const addReportStart = state => {
//   return updateObject(state, {
//     adding: true,
//     error: null,
//   });
// };

// const addReportSuccess = (state, action) => {
//   return updateObject(state, {
//     // reports:action.reports,
//     adding: false,
//     error: null,
//   });
// };

// const addReportCancel = (state, action) => {
//   return updateObject(state, {
//     error: null,
//     adding: false,
//   });
// };

// const selectReport = (state, action) => {
//   return updateObject(state, {
//     selection: action.selection,
//   });
// };

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REPORTS_START:
      return getReportsStart(state, { error: null, loading: true });
    case actionTypes.GET_REPORTS_SUCCESS:
      return getReportsSuccess(state, action);
    case actionTypes.GET_REPORTS_FAIL:
      return getReportsFail(state, action);
    case actionTypes.CREATE_REPORT_START:
      return createReportStart(state, { error: null, loading: true });
    case actionTypes.CREATE_REPORT_SUCCESS:
      return createReportSuccess(state, action);
    case actionTypes.CREATE_REPORT_FAIL:
      return createReportFail(state, action);
    case actionTypes.UPDATE_REPORT_START:
      return updateReportStart(state, { error: null, loading: true });
    case actionTypes.UPDATE_REPORT_SUCCESS:
      return updateReportSuccess(state, action);
    case actionTypes.UPDATE_REPORT_FAIL:
      return updateReportFail(state, action);
    case actionTypes.DELETE_REPORT_START:
      return deleteReportStart(state, { error: null, loading: true });
    case actionTypes.DELETE_REPORT_SUCCESS:
      return deleteReportSuccess(state, action);
    case actionTypes.DELETE_REPORT_FAIL:
      return deleteReportFail(state, action);
    // case actionTypes.ADD_REPORT_START:
    //   return addReportStart(state, { error: null, loading: true });
    // case actionTypes.ADD_REPORT_SUCCESS:
    //   return addReportSuccess(state, action);
    // case actionTypes.ADD_REPORT_CANCEL:
    //   return addReportCancel(state, action);

    // case actionTypes.SELECT_REPORT:
    //   return selectReport(state, action);
    default:
      return state;
  }
};

export default reportReducer;
