import { API } from "aws-amplify";

import * as actionTypes from "./actionTypes";
import config from "../../config";

export const getTrailsStart = () => {
  return {
    type: actionTypes.GET_TRAILS_START,
  };
};

export const getTrailsSuccess = (trails) => {
  return {
    type: actionTypes.GET_TRAILS_SUCCESS,
    trails: trails,
  };
};

export const getTrailsFail = (err) => {
  return {
    type: actionTypes.GET_TRAILS_FAIL,
    error: err,
  };
};

export const getTrails = () => {
  return (dispatch) => {
    dispatch(getTrailsStart());
    const myInit = {
      // OPTIONAL
      headers: {
        "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c",
      },
    };
    API.get("outway", "/trails", myInit)
      .then((res) => {
        console.log(res);
        dispatch(getTrailsSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const createTrailStart = () => {
  return {
    type: actionTypes.CREATE_TRAIL_START,
  };
};

export const createTrailSuccess = (assets) => {
  return {
    type: actionTypes.CREATE_TRAIL_SUCCESS,
    assets: assets,
  };
};

export const createTrailFail = (err) => {
  return {
    type: actionTypes.CREATE_TRAIL_FAIL,
    error: err,
  };
};

export const createTrail = (object) => {
  return (dispatch) => {
    console.log(config);
    dispatch(createTrailStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.post("outway", "/trails/", myInit)
      .then((res) => {
        console.log(res);
        dispatch(createTrailSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const updateTrailStart = () => {
  return {
    type: actionTypes.UPDATE_TRAIL_START,
  };
};

export const updateTrailSuccess = (assets) => {
  return {
    type: actionTypes.UPDATE_TRAIL_SUCCESS,
    assets: assets,
  };
};

export const updateTrailFail = (err) => {
  return {
    type: actionTypes.UPDATE_TRAIL_FAIL,
    error: err,
  };
};

export const updateTrail = (data) => {
  data['trail_attributes'] = data['attributes'];
  return (dispatch) => {
    // console.log(data);
    dispatch(updateTrailStart());
    const myInit = {
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
      body: data,
    };
    API.put("outway", "/trails/" + data.id, myInit)
      .then((res) => {
        console.log(res);
        dispatch(updateTrailSuccess(res));
        dispatch(getTrails()); // TODO: optimize
      })
      .catch((err) => console.log(err));
  };
};

export const deleteTrailStart = () => {
  return {
    type: actionTypes.DELETE_TRAIL_START,
  };
};

export const deleteTrailSuccess = (assets) => {
  return {
    type: actionTypes.DELETE_TRAIL_SUCCESS,
    assets: assets,
  };
};

export const deleteTrailFail = (err) => {
  return {
    type: actionTypes.DELETE_TRAIL_FAIL,
    error: err,
  };
};

export const deleteTrail = (object) => {
  return (dispatch) => {
    console.log(config);
    dispatch(deleteTrailStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.del('outway', '/trails/' + object.id, myInit)
      .then(res => {
        console.log(res);
        dispatch(deleteTrailSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};
