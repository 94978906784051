import { API } from "aws-amplify";

import * as actionTypes from "./actionTypes";
import config from "../../config";
import { alertStatuses } from "../../utilities/mapConstants";

export const getAlertsStart = () => {
  return {
    type: actionTypes.GET_ALERTS_START,
  };
};

export const getAlertsSuccess = (alerts) => {
  return {
    type: actionTypes.GET_ALERTS_SUCCESS,
    alerts: alerts,
  };
};

export const getAlertsFail = (err) => {
  return {
    type: actionTypes.GET_ALERTS_FAIL,
    error: err,
  };
};

export const getAlerts = () => {
  return (dispatch) => {
    dispatch(getAlertsStart());
    const myInit = {
      // OPTIONAL
      headers: {
        "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c",
      }
    };
    API.get("outway", "/alerts", myInit)
      .then((res) => {
        console.log(res);
        dispatch(getAlertsSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};

export const createAlertStart = () => {
  return {
    type: actionTypes.CREATE_ALERT_START,
  };
};

export const createAlertSuccess = (assets) => {
  return {
    type: actionTypes.CREATE_ALERT_SUCCESS,
    assets: assets,
  };
};

export const createAlertFail = (err) => {
  return {
    type: actionTypes.CREATE_ALERT_FAIL,
    error: err,
  };
};

export const createAlert = (object) => {
  console.log(object)
  return (dispatch) => {
    console.log(config);
    dispatch(createAlertStart());
    const myInit = {
      // OPTIONAL
      headers: {
        "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c",
      },
      body: object, // OPTIONAL
    };
    API.post("outway", "/alerts", myInit)
      .then((res) => {
        console.log(res);
        dispatch(createAlertSuccess(res));
        dispatch(getAlerts()); // TODO: optimize
      })
      .catch((err) => console.log(err));
  };
};

export const updateAlertStart = () => {
  return {
    type: actionTypes.UPDATE_ALERT_START,
  };
};

export const updateAlertSuccess = (assets) => {
  return {
    type: actionTypes.UPDATE_ALERT_SUCCESS,
    assets: assets,
  };
};

export const updateAlertFail = (err) => {
  return {
    type: actionTypes.UPDATE_ALERT_FAIL,
    error: err,
  };
};

export const updateAlert = (data) => {
  data['alert_attribute'] = data['attributes'];
  return (dispatch) => {
    // console.log(data);
    dispatch(updateAlertStart());
    const myInit = {
      headers: { "x-api-key": "fb1561b7-54a7-4320-ba4d-b7a7cf8d7f2c" },
      body: data,
    };
    API.put("outway", "/alerts/" + data.id, myInit)
      .then((res) => {
        console.log(res);
        dispatch(updateAlertSuccess(res));
        dispatch(getAlerts()); // TODO: optimize
      })
      .catch((err) => console.log(err));
  };
};

export const deleteAlertStart = () => {
  return {
    type: actionTypes.DELETE_ALERT_START,
  };
};

export const deleteAlertSuccess = (assets) => {
  return {
    type: actionTypes.DELETE_ALERT_SUCCESS,
    assets: assets,
  };
};

export const deleteAlertFail = (err) => {
  return {
    type: actionTypes.DELETE_ALERT_FAIL,
    error: err,
  };
};

export const deleteAlert = (object) => {
  return (dispatch) => {
    console.log(config);
    dispatch(deleteAlertStart());
    const myInit = {
      // OPTIONAL
      headers: {},
      body: object, // OPTIONAL
    };
    API.del('outway', '/alerts/' + object.id, myInit)
      .then(res => {
        console.log(res);
        dispatch(deleteAlertSuccess(res));
      })
      .catch((err) => console.log(err));
  };
};
