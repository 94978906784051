export const snakeToWords = (key) => {
  if (typeof key === 'string') {
    let words = key;
    words = words.replace(/_/g, ' ');
    words = words.charAt(0).toUpperCase() + words.slice(1);
    return words;
  }
  else {
    return key;
  }
};
