import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../utility";

const initialState = {
  error: null,
  loading: false,
  trails: null,
  adding: false,
  selection: null,
};

const getTrailsStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getTrailsSuccess = (state, action) => {
  return updateObject(state, {
    trails: action.trails,
    error: null,
    loading: false,
  });
};

const getTrailsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const createTrailStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const createTrailSuccess = (state, action) => {
  return updateObject(state, {
    trails: action.trails, //TODO: check if this needs to be adjusted to just append to the trails state.
    error: null,
    loading: false,
  });
};

const createTrailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const updateTrailStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const updateTrailSuccess = (state, action) => {
  return updateObject(state, {
    // trails: action.trails, //TODO: check if this needs to be adjusted to just append to the trails state.
    error: null,
    loading: false,
  });
};

const updateTrailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const deleteTrailStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const deleteTrailSuccess = (state, action) => {
  return updateObject(state, {
    trails: action.trails, //TODO: check if this needs to be adjusted to just append to the trails state.
    error: null,
    loading: false,
  });
};

const deleteTrailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

// const addTrailStart = state => {
//   return updateObject(state, {
//     adding: true,
//     error: null,
//   });
// };

// const addTrailSuccess = (state, action) => {
//   return updateObject(state, {
//     // trails:action.trails,
//     adding: false,
//     error: null,
//   });
// };

// const addTrailCancel = (state, action) => {
//   return updateObject(state, {
//     error: null,
//     adding: false,
//   });
// };

// const selectTrail = (state, action) => {
//   return updateObject(state, {
//     selection: action.selection,
//   });
// };

const trailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRAILS_START:
      return getTrailsStart(state, { error: null, loading: true });
    case actionTypes.GET_TRAILS_SUCCESS:
      return getTrailsSuccess(state, action);
    case actionTypes.GET_TRAILS_FAIL:
      return getTrailsFail(state, action);
    case actionTypes.CREATE_TRAIL_START:
      return createTrailStart(state, { error: null, loading: true });
    case actionTypes.CREATE_TRAIL_SUCCESS:
      return createTrailSuccess(state, action);
    case actionTypes.CREATE_TRAIL_FAIL:
      return createTrailFail(state, action);
    case actionTypes.UPDATE_TRAIL_START:
      return updateTrailStart(state, { error: null, loading: true });
    case actionTypes.UPDATE_TRAIL_SUCCESS:
      return updateTrailSuccess(state, action);
    case actionTypes.UPDATE_TRAIL_FAIL:
      return updateTrailFail(state, action);
    case actionTypes.DELETE_TRAIL_START:
      return deleteTrailStart(state, { error: null, loading: true });
    case actionTypes.DELETE_TRAIL_SUCCESS:
      return deleteTrailSuccess(state, action);
    case actionTypes.DELETE_TRAIL_FAIL:
      return deleteTrailFail(state, action);
    // case actionTypes.ADD_TRAIL_START:
    //   return addTrailStart(state, { error: null, loading: true });
    // case actionTypes.ADD_TRAIL_SUCCESS:
    //   return addTrailSuccess(state, action);
    // case actionTypes.ADD_TRAIL_CANCEL:
    //   return addTrailCancel(state, action);

    // case actionTypes.SELECT_TRAIL:
    //   return selectTrail(state, action);
    default:
      return state;
  }
};

export default trailReducer;
