import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../utility";

const initialState = {
  error: null,
  loading: false,
  points: null,
  adding: false,
  selection: null,
};

const getPointsStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getPointsSuccess = (state, action) => {
  return updateObject(state, {
    points: action.points,
    error: null,
    loading: false,
  });
};

const getPointsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const createPointStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const createPointSuccess = (state, action) => {
  return updateObject(state, {
    points: action.points, //TODO: check if this needs to be adjusted to just append to the points state.
    error: null,
    loading: false,
  });
};

const createPointFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const updatePointStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const updatePointSuccess = (state, action) => {
  return updateObject(state, {
    // points: action.points, //TODO: check if this needs to be adjusted to just append to the points state.
    error: null,
    loading: false,
  });
};

const updatePointFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const deletePointStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const deletePointSuccess = (state, action) => {
  return updateObject(state, {
    points: action.points, //TODO: check if this needs to be adjusted to just append to the points state.
    error: null,
    loading: false,
  });
};

const deletePointFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const pointReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_POINTS_START:
      return getPointsStart(state, { error: null, loading: true });
    case actionTypes.GET_POINTS_SUCCESS:
      return getPointsSuccess(state, action);
    case actionTypes.GET_POINTS_FAIL:
      return getPointsFail(state, action);
    case actionTypes.CREATE_POINT_START:
      return createPointStart(state, { error: null, loading: true });
    case actionTypes.CREATE_POINT_SUCCESS:
      return createPointSuccess(state, action);
    case actionTypes.CREATE_POINT_FAIL:
      return createPointFail(state, action);
    case actionTypes.UPDATE_POINT_START:
      return updatePointStart(state, { error: null, loading: true });
    case actionTypes.UPDATE_POINT_SUCCESS:
      return updatePointSuccess(state, action);
    case actionTypes.UPDATE_POINT_FAIL:
      return updatePointFail(state, action);
    case actionTypes.DELETE_POINT_START:
      return deletePointStart(state, { error: null, loading: true });
    case actionTypes.DELETE_POINT_SUCCESS:
      return deletePointSuccess(state, action);
    case actionTypes.DELETE_POINT_FAIL:
      return deletePointFail(state, action);

    // case actionTypes.SELECT_POINT:
    //   return selectPoint(state, action);
    default:
      return state;
  }
};

export default pointReducer;
