import uuid from "uuid/v1";

export default [
  {
    id: uuid(),
    ref: "CDD1049",
    product: "Day Pass",
    amount: 75.67,
    customer: {
      name: "Ekaterina Tankova",
    },
    createdAt: 1555016400000,
    status: "pending",
  },
  {
    id: uuid(),
    ref: "CDD1048",
    product: "3 Day Pass",
    amount: 167.94,
    customer: {
      name: "Cao Yu",
    },
    createdAt: 1555016400000,
    status: "delivered",
  },
  {
    id: uuid(),
    ref: "CDD1047",
    product: "Day Pass",
    amount: 75.67,
    customer: {
      name: "Alexa Richardson",
    },
    createdAt: 1554930000000,
    status: "refunded",
  },
  {
    id: uuid(),
    ref: "CDD1046",
    product: "Season Pass",
    amount: 487,
    customer: {
      name: "Anje Keizer",
    },
    createdAt: 1554757200000,
    status: "pending",
  },
  {
    id: uuid(),
    ref: "CDD1045",
    product: "Day Pass",
    amount: 75.67,
    customer: {
      name: "Clarke Gillebert",
    },
    createdAt: 1554670800000,
    status: "delivered",
  },
  {
    id: uuid(),
    ref: "CDD1044",
    product: "Day Pass",
    amount: 75.67,
    customer: {
      name: "Adam Denisov",
    },
    createdAt: 1554670800000,
    status: "delivered",
  },
  {
    id: uuid(),
    ref: "CDD1043",
    product: "Day Pass",
    amount: 75.67,
    customer: {
      name: "Kendall Barrett",
    },
    createdAt: 1554670800000,
    status: "delivered",
  },
  {
    id: uuid(),
    ref: "CDD1042",
    product: "Day Pass",
    amount: 75.67,
    customer: {
      name: "John Kubansic",
    },
    createdAt: 1554670800000,
    status: "delivered",
  },
];
