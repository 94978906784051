import { makeStyles } from "@material-ui/styles";
const backgroundUrl = "/images/backgrounds/pexels-eberhard-grossgasteiger-6916681.png";

export const useStyles = makeStyles((theme) => ({
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {
    width: "36%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  contentBody: {
    alignItems: "center",
    height: "100%",
    display: "flex",
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  contentHeader: {
    alignItems: "center",
    display: "flex",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  disabledButton: {
    backgroundColor: "#B5D7E4 !important",
    color: "#FFF8F8 !important",
  },
  errorTitle: {
    position: "absolute",
    top: theme.spacing(4.25),
    color: "#87373D",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(5),
  },
  formBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
    padding: "0 64px",
  },
  formContent: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignContent: "flex-start",
  },
  grid: {
    height: "100%",
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  inputDiv: {
    width: "100%",
  },
  inputTitle: {
    alignSelf: "flex-start",
    marginBottom: "16px",
    fontSize: "16px",
  },
  loginLinkContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 64px",
    height: "25vh",
  },
  logoContent: {
    display: "flex",
    marginLeft: "8px",
    maxHeight: "114px",
    marginBottom: "10vh",
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  logoName: {
    backgroundColor: 'black',
    marginLeft: '8px',
    marginTop: '14px',
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  root: {
    backgroundColor: "#FFFFFF",
    height: "100%"
  },
  quote: {
    alignItems: "center",
    backgroundColor: theme.palette.neutral,
    backgroundImage: `url(${backgroundUrl})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    height: "100%",
    justifyContent: "center",
  },
  quoteInner: {
    flexBasis: "600px",
    textAlign: "center",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  signInDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    '& p': {
      textAlign: "center",
    },
  },
  signInButton: {
    color: "#FFFFFF",
    backgroundColor: "#007DAD",
    marginBottom: theme.spacing(1),
    margin: "0 auto",
    maxWidth: "50%",
    textTransform: 'none',
  },
  signupLinkContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 64px",
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    letterSpacing: "1px",
    marginBottom: theme.spacing(6),
    position: "relative",
    textAlign: "center",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));
