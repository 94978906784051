import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer, Hidden, IconButton } from "@material-ui/core";

import NotificationsIcon from '@material-ui/icons/Notifications';
import LanguageIcon from '@material-ui/icons/Language';
import MessageIcon from '@material-ui/icons/Message';
import GroupIcon from '@material-ui/icons/Group';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsIcon from '@material-ui/icons/Settings';

import { Profile, SidebarNav } from "./components";
import Logout from "../Logout";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  drawer: {
    width: theme.spacing(25),
    flexShrink: 0,
    whiteSpace: "nowrap",
    '& .makeStyles-root-5 img': {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(0.5),
    },
  },
  drawerOpen: {
    width: theme.spacing(25),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRight:"none",
    overflowX: "hidden",
    '& .makeStyles-bottomSidebar-11 button': {
      position: "relative",
      right: -theme.spacing(8),
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderRight:"none",
    overflowX: 'hidden',
    width: theme.spacing(9) + 1,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(2),
  },
  bottomSidebar: {
    textAlign: 'center',
    position: "absolute",
    bottom: 25,
    left: 0,
    width: '100%',
    padding: 16,
    borderRight:"none",
  },
}))

const Sidebar = (props) => {
  const { onOpen, onClose, open, className, logOutHandler, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: "Notifications",
      href: "",
      icon: <NotificationsIcon />,
    },
    {
      title: "Map",
      href: "/trails",
      icon: <LanguageIcon />,
    },
    // {
    //   title: "Authentication",
    //   href: "/sign-in",
    //   icon: <LockOpenIcon />,
    // },
    {
      title: "Messages",
      href: "",
      icon: <MessageIcon />,
    },
    {
      title: "Team",
      href: "/users",
      icon: <GroupIcon />,
      requiresAdmin: true,
    },
    {
      title: "Profile",
      href: "/account",
      icon: <AccountCircleIcon />,
    },
    // {
    //   title: "Settings",
    //   href: "/settings",
    //   icon: <SettingsIcon />,
    // },
  ];

  const settingsPage = [
    {
      title: "Settings",
      href: "",
      icon: <SettingsIcon />,
    },
  ];

  return (
    <Drawer
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      // classes={{ paper: classes.drawer }}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      variant='permanent'
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <img
            alt="Logo"
            height="35vh"
            width="35vh"
            src="/images/logos/outway_logo_main_black.png"
          />
        <SidebarNav className={classes.nav} pages={pages} isOpen={open}/>
        {/* <Profile /> */}
        <Hidden lgUp>
          <Logout clicked={logOutHandler} />
        </Hidden>
        <div className={classes.bottomSidebar}>
          <SidebarNav className={classes.nav} pages={settingsPage} isOpen={open}/>
          <IconButton onClick={open ? onClose : onOpen}>
            {open ? <ArrowBackIcon /> : <ArrowForwardIcon/>}
          </IconButton>
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  logOutHandler: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
