import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const FullScreenSpinner = () => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.root}
      container
      direction={'column'}
      alignItems={'center'}
      justify={'center'}
    >
      <CircularProgress size={'8rem'} />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
}));

export default FullScreenSpinner;
