import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
    maxWidth: 345,
    width: "30%",
    position: "absolute",
    zIndex: 110,
    transform: "translate(485px, 248.421875px)",
    // bottom:0,
    // marginBottom:theme.spacing(8),
    // marginLeft:theme.spacing(4)
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    marginLeft: "8px",
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: "#eee",
  },
  cardHeader: {
    padding: 2,
  },
}));

const HoverCard = (props) => {
  const classes = useStyles();
  // const [expanded, setExpanded] = React.useState(false);
  const object = props.object;

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  return (
    <Card
      className={classes.card}
      style={{ left: props.horizontal, top: props.vertical }}
    >
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <img alt="" src={object.iconUrl} style={{ height: "20px" }} />
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={object.name}
        subheader={"Difficulty: " + object.difficulty}
      />
    </Card>
  );
};

export default HoverCard;
