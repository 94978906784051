import React from 'react'
import { TextareaAutosize } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(1),
        background: '#FFFFFF',
        border: '1px solid #007DAD',
        boxSizing: 'border-box',
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
        },
    }
}))

export default function OutwayTextarea(props) {

    const {className, data, ...other} = props;
    const classes = useStyles();

    console.log(data);

    return (
        <TextareaAutosize
        className={className ? `${classes.root} ${className}` : classes.root}
        {...other}
        >

        </TextareaAutosize>
    )
}