import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../utility";

const initialState = {
  authenticated: null,
  userId: null,
  error: null,
  isLoading: false,
  authRedirectPath: "/",
  cognitoUser: null,
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    isLoading: true,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    error: "",
    authenticated: true,
    mfa: false,
    isLoading: false,
    cognitoUser: action.payload,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const authLogoutStart = (state, action) => {
  return updateObject(state, { error: null, isLoading: true });
};

const authLogoutSuccess = (state, action) => {
  return updateObject(state, {
    authenticated: null,
    userId: null,
    cognitoUser: null,
  });
};

const authLogoutFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

// charlie: moved this to auth success
const authUser = (state, action) => {
  return updateObject(state, {
    error: "",
    authenticated: true,
    mfa: false,
    isLoading: false,
    cognitoUser: action.payload,
  });
};

const authMFA = (state, action) => {
  return updateObject(state, {
    error: "",
    authenticated: false,
    mfa: true,
    changePassword: false,
    cognitoUser: action.payload,
    isLoading: false,
  });
};

const authNewPasswordRequired = (state, action) => {
  return updateObject(state, {
    error: "",
    authenticated: false,
    mfa: false,
    changePassword: true,
    cognitoUser: action.payload,
    isLoading: false,
  });
};

const unauthUser = (state, action) => {
  return updateObject(state, {
    error: "",
    authenticated: false,
    isLoading: false,
    cognitoUser: null,
  });
};

const signupUser = (state, action) => {
  return updateObject(state, {
    error: "",
    authenticated: false,
    signup: true,
    isLoading: false,
  });
};

const signupUserConfirm = (state, action) => {
  return updateObject(state, {
    error: "",
    authenticated: false,
    signup: true,
    mfa: false,
    resendCode: false,
    isLoading: false,
    password: null,
  });
};

const signupMFA = (state, action) => {
  return updateObject(state, {
    error: "",
    mfa: true,
    resendCode: false,
    cognitoUser: action.payload.userSub, // TODO: this should persist on page reload
    password: action.payload.password,
  });
};

const signupUserError = (state, action) => {
  return updateObject(state, {
    error: action.payload,
    mfa: true,
    resendCode: true,
    cognitoUser: action.cognitoUser,
  });
};

const authError = (state, action) => {
  return updateObject(state, {
    error: action.payload,
    authenticated: false,
  });
};

const authErrorClean = (state, action) => {
  return updateObject(state, {
    error: "",
    authenticated: false,
  });
};

const forgotPasword = (state, action) => {
  return updateObject(state, {
    error: "",
    forgotPasswordCode: true,
    authenticated: false,
  });
};

const forgotPaswordConfirm = (state, action) => {
  return updateObject(state, {
    error: "",
    forgotPasswordCode: false,
    authenticated: false,
  });
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT_START:
      return authLogoutStart(state, action);
    case actionTypes.AUTH_LOGOUT_SUCCESS:
      return authLogoutSuccess(state, action);
    case actionTypes.AUTH_LOGOUT_FAIL:
      return authLogoutFail(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.AUTH_USER:
      return authUser(state, action);
    case actionTypes.AUTH_MFA:
      return authMFA(state, action);
    case actionTypes.AUTH_NEW_PASSWORD_REQUIRED:
      return authNewPasswordRequired(state, action);
    case actionTypes.UNAUTH_USER:
      return unauthUser(state, action);
    case actionTypes.SIGNUP_USER:
      return signupUser(state, action);
    case actionTypes.SIGNUP_USER_CONFIRM:
      return signupUserConfirm(state, action);
    case actionTypes.SIGNUP_MFA:
      return signupMFA(state, action);
    case actionTypes.SIGNUP_USER_ERROR:
      return signupUserError(state, action);
    case actionTypes.AUTH_ERROR:
      return authError(state, action);
    case actionTypes.FORGOT_PASSWORD:
      return forgotPasword(state, action);
    case actionTypes.FORGOT_PASSWORD_CONFIRM:
      return forgotPaswordConfirm(state, action);
    case actionTypes.AUTH_ERROR_CLEAR:
      return authErrorClean(state, action);
    default:
      return state;
  }
};

export default authReducer;
