import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as actions from "../../store/actions";
import { Sidebar, Topbar } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: 56,
    height: "100%",
    // [theme.breakpoints.up("sm")]: {
    //   paddingTop: "6.5vh",
    // },
    paddingLeft: theme.spacing(9)
  },
  shiftContent: {
    paddingLeft: 200,
  },
  content: {
    height: "100%",
    backgroundColor: "#F6F8F9"
  },
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(true);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleLogout = () => {
    props.onLogout({ username: "admin" }, props.history);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: openSidebar,
      })}
    >
      {/* <Topbar logOutHandler={handleLogout} onSidebarOpen={handleSidebarOpen} /> */}
      <Sidebar
        logOutHandler={handleLogout}
        onOpen={handleSidebarOpen}
        onClose={handleSidebarClose}
        open={openSidebar}
        // variant={isDesktop ? "persistent" : "temporary"}
      />
      <main className={classes.content}>
        {children}
        {/* <Footer /> */}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  onLogout: PropTypes.func,
  action: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: ({ username }, history) =>
      dispatch(actions.logout({ username }, history)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
