import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.black,
    main: colors.grey[900],
    light: colors.grey[800],
  },
  secondary: {
    contrastText: black,
    dark: colors.white,
    main: colors.grey[50],
    light: colors.grey[200],
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[800],
    light: colors.green[700],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: black,
    secondary: colors.grey[800],
    link: colors.blue[600],
  },
  background: {
    default: colors.grey[200],
    paper: colors.grey[100],
  },
  // icon: "#c25e00",
  icon: "#000000",
  divider: colors.grey[600],
};
