import React from 'react';
import { 
    FormControl, 
    InputLabel,
    makeStyles, 
    Select as MuiSelect 
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '14px',
        letterSpacing: '0.2px',
        color: '#888785',
        minWidth: '100%',
        height: theme.spacing(6),
        border: '1px solid #007DAD',
        '& .MuiFormControl-root': {
            width: '100%',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
            backgroundColor: 'transparent',
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
    },
}))

export default function OutwayDropdown(props) {

    const { children, name, label, value, onChange, className, ...others } = props;
    const classes = useStyles();

    return (
        <FormControl
        variant="outlined"
        fullWidth
        >
            <InputLabel>{label}</InputLabel>
            <MuiSelect
            label={label}
            className={className ? `${classes.root} ${className}` : classes.root}
            name={name}
            value={value}
            onChange={onChange}
            {...others}
            >
                {children}
            </MuiSelect>
        </FormControl>
    )
}
