import { Auth } from "aws-amplify";
import { createUser, clearUser } from "./users";

import * as actionTypes from "./actionTypes";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (response) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    payload: response,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const authLogoutStart = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_START,
  };
};

export const authLogoutSuccess = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_SUCCESS,
  };
};

export const authLogoutFail = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_FAIL,
  };
};

// export  const logout = () => {
//   return async dispatch => {

//     await Auth.signOut();

//     return(false);

//     }
//   };

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

// export const auth = (email, password, isSignUp) => {
//   return dispatch => {
//     dispatch(authStart());
//     let url = 'http://localhost:3001/api';
//     if (!isSignUp){
//       url='http://localhost:3001/api'
//     }
//     fetch(url, {
//       method: 'POST',
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//       },
//       // credentials: 'same-origin',
//       body: JSON.stringify({
//         username: email,
//         password: password,
//       })})
//       .then(response => response.json())
//       .then(jsondata => {
//         if (jsondata.status){
//           dispatch(authSuccess(jsondata))
//         } else {
//           throw jsondata;
//         }
//       })
//       .catch(err => {
//         console.log(err);
//         dispatch(authFail(err));
//       })
//   };
// };

// Cognito - Auth.signIn()
// export const login = (username, password, history) => {
//   return async dispatch => {
//     console.log('actions.login(): username password:', { username, password } );
//       // setIsLoading(true);
//       try {
//         await Auth.signIn(email, password);
//         dispatch(authSuccess());
//       } catch (e) {
//         alert(e.message);
//         dispatch(authFail(e));
//       }
//    }
// }

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return async (dispatch) => {
    try {
      await Auth.currentSession();
      return true;
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
    return false;
    // const token = localStorage.getItem('token');
    // const cookie = document.cookie
    // console.log(cookie)
    // if (!cookie){
    //   dispatch(logout());
    // } else {
    // const expirationDate = new Date(localStorage.getItem('expirationDate'));
    // console.log(expirationDate)
    // if (expirationDate <= new Date()) {
    //   dispatch(logout())
    // } else {
    // const userId = localStorage.getItem('userId');
    // const userId = document.cookie[userId];
    // dispatch(authSuccess(userId));
    // dispatch(checkAuthTimeout((expirationDate.getSeconds() - new Date().getTime()/1000)));
    // }
  };
};

export const authError = (error) => {
  return {
    type: actionTypes.AUTH_ERROR,
    payload: error.message,
  };
};

// Cognito - Auth.signIn()
export const login = (username, password, history) => {
  return async (dispatch) => {
    console.log("actions.login(): username password:", username, password);
    // signIn (cognito)
    await Auth.signIn(username, password)
      .then((data) => {
        // success -- dispatch AUTH_USER
        console.log("actions.login():Auth.signIn() data:", data);
        // inspect response 'data' and check whether
        // 1. MFA confirmation is required, dispatch->AUTH_MFA
        // 2. New Password is required (change 'FORCE_CHANGE_PASSWORD'
        //    to 'CONFIRMED'), dispatch-> AUTH_NEW_PASSWORD_REQUIRED with payload
        // 3. otherwise, authenticate user, dispatch -> AUTH_USER
        if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
          dispatch({
            type: actionTypes.AUTH_NEW_PASSWORD_REQUIRED,
            payload: data,
          });
        } else if (
          data.challengeName === "MFA_REQUIRED" ||
          data.challengeName === "SMS_MFA"
        ) {
          dispatch({ type: actionTypes.AUTH_MFA, payload: data });
        } else {
          // dispatch AUTH_USER
          dispatch({ type: actionTypes.AUTH_USER, payload: data.username });
          // we have authenticated, lets navigate to /main route
          history.push("/");
        }
      })
      .catch((err) => {
        console.error("actions.login():Auth.signIn() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
};

// Cognito - Auth.currentAuthenticatedUser()
// Cognito - Auth.userSession()
// This is a pass-through function to indicate that user has already authenticated
// and has a valid Amplify session.
export const validateUserSession = () => {
  return (dispatch) => {
    dispatch(authStart());
    Auth.currentAuthenticatedUser()
      .then((currentAuthUser) => {
        // grab the user session
        Auth.userSession(currentAuthUser)
          .then((session) => {
            // finally invoke isValid() method on session to check if auth tokens are valid
            // if tokens have expired, lets call "logout"
            // otherwise, dispatch AUTH_USER success action and by-pass login screen
            if (session.isValid()) {
              // fire user is authenticated
              dispatch(authSuccess(currentAuthUser.username));
              //history.push('/');
            } else {
              // fire user is unauthenticated
              dispatch({ type: actionTypes.UNAUTH_USER });
              // history.push('/');
            }
          })
          .catch((err) => {
            // error occured during session validation, fire user is unauthenticated
            dispatch({ type: actionTypes.UNAUTH_USER });
            //history.push('/');
          });
      })
      .catch((err) => {
        console.error(
          "actions.validateUserSession():Auth.currentAuthenticatedUser() err:",
          err
        );
        // error occured while retrieving current auth user, fire user is unauthenticated
        dispatch({ type: actionTypes.UNAUTH_USER });
        //history.push('/');
      });
  };
};
// Cognito - Auth.completeNewPassword()
export const setNewPassword = ({ cognitoUser, newPassword }, history) => {
  return (dispatch) => {
    console.log("actions.setNewPassword(): cognitoUSer, newPassword:", {
      cognitoUser,
      newPassword,
    });

    // completeNewPassword (cognito)
    Auth.completeNewPassword(cognitoUser, newPassword)
      .then((data) => {
        console.log(
          "actions.setNewPassword():Auth.completeNewPassword() data: ",
          data
        );

        // inspect response 'data' and check whether
        // 1. MFA confirmation is required, dispatch->AUTH_MFA
        // 2. otherwise, authenticate user, dispatch -> AUTH_USER
        if (data.challengeName === "SMS_MFA") {
          dispatch({ type: actionTypes.AUTH_MFA, payload: data });
        } else {
          // dispatch AUTH_USER
          dispatch({ type: actionTypes.AUTH_USER });

          // we have authenticated, lets navigate to /main route
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(
          "actions.setNewPassword():Auth.completeNewPassword() err:",
          err
        );
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
};

// Cognito - Auth.signOut()
export const logout = ({ username }, history) => {
  console.log(username, history);
  return (dispatch) => {
    console.log("actions.logout(): username: ", username);

    // signOut (cognito)
    Auth.signOut()
      .then((data) => {
        console.log("actions.logout():Auth.signOut() data:", data);

        dispatch({ type: actionTypes.UNAUTH_USER });
        dispatch(clearUser());

        // we have authenticated, lets navigate to /main route
        history.push("/login");
      })
      .catch((err) => {
        console.error("actions.logout():Auth.signOut() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
};

// Cognito - Auth.confirmSignIn()
export const confirmLogin = ({ cognitoUser, code }, history) => {
  return (dispatch) => {
    console.log("actions.confirmLogin(): cognitoUSer, code:", {
      cognitoUser,
      code,
    });

    // confirmSignIn (cognito)
    Auth.confirmSignIn(cognitoUser, code)
      .then((data) => {
        console.log("actions.confirmLogin():Auth.confirmSignIn() data: ", data);
        // dispatch AUTH_USER
        dispatch({ type: actionTypes.AUTH_USER });
        // we have authenticated, lets navigate to /main route
        history.push("/signin");
      })
      .catch((err) => {
        console.error("actions.confirmLogin():Auth.confirmSignIn() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
};

// Cognito - Auth.signUp()
export const signup = (newUser, history) => {
  return (dispatch) => {
    let { first_name, last_name, email, password } = newUser;
    const username = email;
    if (!password) password = "Password*123";
    console.log("actions.signup(): username, password, email: ", {
      username,
      password,
      email,
      // phone,
    });

    // signUp (cognito)
    Auth.signUp(username, password)
      .then((data) => {
        console.log("actions.signup():Auth.signUp() data:", data);

        // MFA is required for user registration
        if (
          typeof data.userConfirmed != "undefined" &&
          data.userConfirmed === false
        ) {
          data.password = password;
          dispatch({ type: actionTypes.SIGNUP_MFA, payload: data });
          newUser.cognito_id = data.userSub;
          dispatch(createUser(newUser));
        } else {
          dispatch({ type: actionTypes.SIGNUP_USER });
          // user registration successful, lets navigate to / route
          history.push("/");
        }
      })
      .catch((err) => {
        console.error("actions.signup():Auth.signUp() err:", err);

        // error -- invoke authError which dispatches SIGNUP_USER_ERROR
        dispatch(authError(err));
      });
  };
};

// Cognito - Auth.confirmSignUp()
export const confirmRegistration = (
  email,
  password,
  username,
  code,
  history
) => {
  return (dispatch) => {
    console.log("actions.confirmRegistration(): cognitoUSer, code:", {
      username,
      code,
    });
    // confirmSignUp (cognito)
    Auth.confirmSignUp(username, code)
      .then((data) => {
        console.log(
          "actions.confirmRegistration():Auth.confirmSignUp() data: ",
          data
        );
        // A successful registration response doesnt contain idToken.
        // So we must redirect to login screen
        // dispatch SIGNUP_USER_CONFIRM
        dispatch({ type: actionTypes.SIGNUP_USER_CONFIRM });
        dispatch(login(email, password, history));
        // we have authenticated, lets navigate to /main route
        // history.push('/');
      })
      .catch((err) => {
        console.error(
          "actions.confirmRegistration():Auth.confirmSignUp() err:",
          err
        );

        // error -- invoke authError which dispatches AUTH_ERROR
        //dispatch(authError(err));
        dispatch({
          type: actionTypes.SIGNUP_USER_ERROR,
          payload: err.message,
          username,
        });
      });
  };
};

// Cognito - Auth.resendSignUp()
export const resendConfirmationCode = ({ cognitoUser }, history) => {
  return (dispatch) => {
    console.log("actions.resendConfirmationCode(): username: ", {
      cognitoUser,
    });
    const { username } = cognitoUser.user;
    // resendSignUp (cognito)
    Auth.resendSignUp(username)
      .then((data) => {
        console.log(
          "actions.resendConfirmationCode():Auth.resendSignUp() data:",
          data
        );
        dispatch({ type: actionTypes.SIGNUP_MFA, payload: cognitoUser });
      })
      .catch((err) => {
        console.error(
          "actions.confirmForgotPassword():Auth.forgotPasswordSubmit() err:",
          err
        );
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
};

// Cognito - Auth.forgotPassword()
export const forgotPassword = ({ username }, history) => {
  return (dispatch) => {
    console.log("actions.forgotPassword(): username: ", { username });
    // forgotPassword (cognito)
    Auth.forgotPassword(username)
      .then((data) => {
        console.log(
          "actions.forgotPassword():Auth.forgotPassword() data:",
          data
        );
        dispatch({ type: actionTypes.FORGOT_PASSWORD });
      })
      .catch((err) => {
        console.error(
          "actions.forgotPassword():Auth.forgotPassword() err:",
          err
        );
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
};

// Cognito - Auth.forgotPasswordSubmit()
export const confirmForgotPassword = (
  { username, code, newPassword },
  history
) => {
  return function (dispatch) {
    console.log(
      "actions.confirmForgotPassword(): username, code, newPassword: ",
      {
        username,
        code,
        newPassword,
      }
    );
    // forgotPasswordSubmit (cognito)
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((data) => {
        console.log(
          "actions.confirmForgotPassword():Auth.forgotPasswordSubmit() data:",
          data
        );
        // TODO - User password changed successfully, do we need to login again?
        dispatch({ type: actionTypes.FORGOT_PASSWORD_CONFIRM });
        history.push("/");
      })
      .catch((err) => {
        console.error(
          "actions.confirmForgotPassword():Auth.forgotPasswordSubmit() err:",
          err
        );
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
};
